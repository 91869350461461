.cgr__wrapper {
    padding: .5rem;
    &.expanded {
        @include default--container();
    }
    @include default--container();
}

.cgr__container--countdown {
    border-radius: $border--radius;
}

.cgr__wrapper--header {
    background-color: transparent !important;
}