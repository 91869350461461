.shop__container--text {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: $text__size--large;
    flex-flow: column nowrap;
    text-align: center;
    background:  linear-gradient(#d19f02, #d28b04);
    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--normal;
    }

    .title {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        font-weight: $font-weight-medium !important;
        font-size: $text__size--x-large;
        text-shadow: 1px 1px 1px #4d3702;
        @media screen and (max-width: $break-point--768) {
            font-size: $text__size--large;
        }

        .amount-icon {
            margin: 0 .5rem;
            white-space: nowrap;
            display: inline-block;
            direction: ltr;
        }

        .amount {
            font-size: inherit;
        }
    }
}


.shop__text--colored {
    color: $color--white;
}