.knockout__tile--image {
    display: block;
    background-image: url(imageUrl("lobby/Knockoutchallenge.png"));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    height: 20%;
    width: 100%;
    box-shadow: inset -3px 0px 0 #7100a8, inset 3px 0px 0 #7100a8, inset 0 3px 0 #7f30a8;
    border-radius: 2rem 2rem 0 0;
    background-color: #5b097b;
}

.knockout__tile--wrapper {
    border-radius: 2rem;
}

.knockout__tile--container {
    height: 80%;
    position: relative;
    background: linear-gradient(90deg,#590080,#590080 2%,#3F0355 13%,#3F0355 50%,#3F0355 87%,#590080 98%,#590080);
    border-radius: 0 0 1.5rem 1.5rem;
    box-shadow: inset 0 5px 0 #38003E, inset 0 -4px 0 #9002E8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.knockout__wrapper {
    height: initial;
    margin-bottom: 2rem;
}


.knockout__tournament--footer-bar {
    position: initial !important;
    background-color: transparent !important;
}

.knockout__tile__marble {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #13011D , transparent 4%, transparent 96%, #13011D);
    z-index: 2;
    pointer-events: none;
    border-radius: 0 0 2rem 2rem;
}