.item-reward__reward {
    @include aspect-ratio(0.725, 1);
    width: auto !important;
    height: auto !important;
    border: none;
    border-radius: 1.5rem;

    &.backgroundImage {
        box-shadow: inset 0 3px 0 #fef2e4, inset 0 -3px 0 #724930, inset 3px 0 0 #dc855c, inset -3px 0 0 #dc855c,  0 3px 5px #000;
        background-color: #f4e4d4;
        .item-reward__reward--text {
            background-color: #c38560;
            text-shadow: -1px -1px 0 #865332, 1px -1px 0 #865332, -1px 1px 0 #865332, 1px 1px 0 #865332;
            align-items: center;
            &.no-template {
                display: none;
            }
        }
    }

    &.current {
        box-shadow: inset 0 3px 0 #fef2e4, inset 0 -3px 0 #724930, inset 3px 0 0 #dc855c, inset -3px 0 0 #dc855c, 0 0 16px 8px #a8896c;
        border: none !important;
    }

    &.locked {
        filter: brightness(0.6);
    }

    &.claimed {
        background-image: none !important;;
        background-color: #f4e4d4;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: $black__transparent--05;
            opacity: 0.8;
        }
    }
}

.item-reward__reward--booster {
    font-weight: $font-weight-bold;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.item-reward__reward--text {
    border-radius: 0.8rem;
    padding: 0.2rem;
    height: 2.5rem;
    width: 85%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: $break-point--360) {
        padding: 0;
        height: 2rem;
    }
}

.item-reward--content {
    padding: .7rem 0 !important;
}

.item-reward__reward--image--wrapper {
    flex-shrink: 0;
    height: 75% !important;
    @media only screen and (max-width: $break-point--440) {
        height: 70% !important;
    }
}

.item-reward__reward--text {
    height: 25% !important;
    @media only screen and (max-width: $break-point--440) {
        height: 30% !important;
    }
}