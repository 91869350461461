.shop-text-list__wrapper {
    &:not(.simple-styling) {
        @include default--container();
    }
}

.shop-text-list__container--benefits-label {
    &:not(.simple-styling) {
        background-color: transparentize($container--1, .8);
        border-radius: 1.5rem 1.5rem 0rem 0rem;
    }
}

.shop-text-list__container--benefits-label {
    &.simple-styling {
        color: $accent--1;
    }
}

.shop-text-list__container--benefits-list {
    padding: 1rem .5rem;
}