.stamp-card__top-container {
    background-image: url(imageUrl('shop/stamp-card/TOP.png'));
}

.stamp-card__bottom-container {
    background-image: url(imageUrl('shop/stamp-card/BOTTOM.png'));
    background-size: cover;
    background-position: top;
}

.stamp-card__flag__container {
    border-top: 2px solid #7a7f84 !important;
}

.stamp-card__middle-container {
    background: url(imageUrl('shop/stamp-card/LINE.png')) #e4e5e9;
}
