/*********************
* Empty Content skin *
*********************/

// Styles
.empty-content__container--no-notifications-image {
    @include background-image(imageUrl("/platform-logos/baloot.svg"), auto, 100%, center, center);
    background-size: 60% 80% !important;

    &:after {
        @include separator(yellow);
    }
}

.empty-content__text--no-notifications {
    margin-bottom: 2rem;
}