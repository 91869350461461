.betting-game-tile__logo {
    background-size: cover;
    width: 100% !important;
    height: 100% !important;
    background-position: center;
    background-image: none !important;
    display: block !important;
}

.betting-game-tile__container {
    @include container--tile(transparent, #c959db, #9a01ab, #670363);
    background-image: url(imageUrl("lobby/DreamMachine.png")) !important;
    background-position: center;
}

.betting-game-tile__button__container {
    background-color: transparent !important;
    padding: 1rem !important;
}

.betting-game-tile__logo {
    border-radius: 1.5rem;
    height: calc(100% - 8px) !important;
    width: calc(100% - 8px) !important;
}

.betting-game-tile__button {
    height: 3.5rem !important;

    @media only screen and (max-width: $break-point--768) {
        height: 3rem !important;
    }
}