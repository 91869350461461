.free-reward-box__container--main {
    @include default--container();
}

.free-rewards-popup__content-wrapper {
    --top-tabs-height: 12rem !important; 
    height: calc(100% - var(--top-tabs-height)) !important;
}

.free-rewards-popup__container--content {
    padding: 0 1.5rem 1.5rem 1.5rem !important;
}

.free-rewards__top-tabs__bclass {
    @include top--tab();

    &.free-rewards__top-tabs__bclass__selected {
        @include top--tab--selected();
    }
}