.invite-page__top-tabs__bclass {
  @include top--tab();

  &.top-tabs__selected {
    @include top--tab--selected();
  }
}

.invite-page__list-item--navigation {
  height: auto !important;
}

.cg-invite-popup__tab-container {
  margin-top: 0.5rem !important;
}