$chat__topic-message--color: #0D131F;

.conversation--container {
    border-radius: 2rem;
    background-color: #f9f9f9;
    @include touch-hover() {
        background-color: #DDD3CE;
        border: none;
    }
}

.conversation--unread {
    border-radius: 2rem;
    background-color: #C0E4F7;
}

.text--username {
    color: #0d131f;
}

.dropdown--item--button {
    color: #0c121e;
    > p {
        color: #0c121e;
        text-shadow: none;
        font-size: $text__size--normal !important;
    }
}

.button--dropdown__toggle {
    color: #c47c2a;
}

.menu--dropdown__toggle--button--opened {
    color: #c47c2a;
}

.conv--icon {
    color: #0c121e;
}

.button--delete-format {
    color: #0c121e !important;
}

.button--like-format {
    color: #0c121e !important;
}