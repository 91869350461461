.ranked-season-tile__container {
    box-shadow: inset 0 2px 0 #ffffff30, inset 0 -4px 0 #00000096, inset 3px 0 0 #00000078, inset -3px 0 0 #00000078;
    border-radius: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.ranked-season-tile__name {
    font-size: 3vh;
    line-height: 1;
}