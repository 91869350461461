.chips-item--img-ontainer {
    transform: scale(1.5);
    @media only screen and (max-width: $break-point--440) {
        transform: scale(1);
    }

    >img {
        max-height: 100% !important;

        @media screen and (max-width:$break-point--480) {
            max-width: 100% !important;
        }
    }
}

.chips-item__text-value {
    &.currency-component-host {
        font-size: 2rem !important;
        @media only screen and (max-width: $break-point--440) {
            font-size: 1.6rem !important;
        }
    }
}