.first-purchase-flag__text {
    font-weight: $font-weight-normal;
    color: #001858;
}

.first-purchase-flag__container {
    border: none !important;
    background: linear-gradient(0deg,#e6cabf,#faf5f3) !important;
    box-shadow: 0 10px 20px #9e0430;
}

.first-purchase-offer__button {
    position: relative;
    border-radius: 10rem;
    box-shadow: 0 2px 3px 0 black;
}

.first-purchase-offer__item {
    @include item-component((width:45%, height: auto));
    background-color: #001859;
    box-shadow: inset 0 -2px 0 #9ac3d6,inset 1px 0 0 #005f9f,inset -1px 0 0 #005f9f,inset 0 2px 2px #00042f, inset 0 0 25px #0030b4;
    
    border-radius: 1.5rem;
    @media only screen and (max-width: $break-point--480) {
        @include item-component((width:60%, height: auto));
        font-size: 1.4rem;
    }
}


.first-purchase-offer__title {
    margin: 10rem 0 0.5rem !important;
    color: #f7e7d7;
    text-shadow: 0 2px 2px #013962;
    @media only screen and (max-width: $break-point--480) {
        margin: 8rem 0 0.5rem !important;
    }
    @media only screen and (max-width: $break-point--440) {
        margin: 7rem 0 0.5rem !important;
    }
    @media only screen and (max-width: $break-point--380) {
        margin: 6rem 0 0.5rem !important;
    }
}

.first-purchase-offer__container {
    border-radius: 10rem 10rem 2rem 2rem;
    background-color: #3ea3d2;
    overflow: visible !important;
    display: flex;
    box-shadow: inset 0 -2px 2px #00486a, 0 9px 6px #011034;
    border-bottom: 5px solid #1b3ea3;
    flex-direction: column;
    @media only screen and (max-width: $break-point--568) {
        border-radius: 8rem 8rem 2rem 2rem;
    }
    @media only screen and (max-width: $break-point--480) {
        border-radius: 6rem 6rem 2rem 2rem;       
    }

    //ramadan
    // background-color: #008d8d;
    // border-bottom: 5px solid #004c59;
    // box-shadow: inset 0 -2px 2px #004c59, 0 9px 6px #011034;
}

.first-purchase-offer__img {
    left: -7% !important;
    width: 114% !important;
    height: 50% !important;
    object-fit: contain !important;
    object-position: top;
}