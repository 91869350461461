/***********************
* Toggle button skin   *
***********************/

// Styles
.cmn-toggle {
  background: none;
  background-color: #021243;
  box-shadow: 0 0.1rem 0.5rem 0.2rem $black__transparent--05;
  .cmn-toggle-round {
    background-color: #f4e4d4;
    box-shadow: 0 0.1rem 0.5rem 0.2rem $black__transparent--05;
  }
}

.cmn-toggle {
  &.checkedToggle {
    background-color: #2f9f4d;
    &:before {
      background-color: #2f9f4d;
    }
    .cmn-toggle-round {
      background-color: #f4e4d4;
    }
  }
}

.toggle-button__checkbox {
  border: .1rem solid $accent--1;
  &.toogle-button__checked {
    background-color: $accent--1;
  }
}