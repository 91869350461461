.gifts-tab__container--premium-gifts {
    border: none;
}

.gift-container__wrapper--gift-item {
    // &.in-game {
        @include default--container();
    // }
}

.gift-container__wrapper--gift-item__premium {
    // &.in-game {
        @include special--container();
    // }
}

.gifts-tab__text--premium-gifts {
    margin: 1rem 0 4rem;
}

.image-container {
    @include aspect-ratio(1,1);
    margin: 1rem 1.5rem !important;
}