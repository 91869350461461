.notification-wrapper__container--main {
    &.gifts,
    &.invites,
    &.likes,
    &.likesandratings,
    &.club,
    &.system {
        @include default--container();
        &.highlighted {
            @include accent--container();
        }
    }
}