.progressive-ads-bonus__reward-wrapper {
    border: none;
    overflow: visible;
    background: transparent;

    &.current {
        padding: 0.5em;
        background-color: transparent;
        border-bottom: none !important;
        border-radius: $container-border--radius;
        box-shadow: none;

        @media screen and (max-width: $break-point--568) {
            border-radius: 2vh;
        }
    }
}

.progressive-ads-bonus__reward {
    @include aspect-ratio(0.725, 1);
    width: auto !important;
    height: auto !important;
    border: none;
    background-color: transparent;
    background-image: var(--item-reward-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10%;

    &.current {
        box-shadow: 0 0 18px 3px #134be3;
    }

    &.locked {
        filter: brightness(0.6);
        box-shadow: 0 15px 18px 0 rgb(68 25 78 / 60%);
    }

    &.claimed {
        background-image: var(--item-reward-bg);
        background-color: #062b33;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: #0b0b1a;
            opacity: 0.8;
        }
    }
}

.progressive-ads-bonus__reward--booster {
    font-weight: $font-weight-bold;
    text-align: center;
}

.progressive-ads-bonus__reward--icon {
    color: $color--white;
    bottom: 20%;
    right: -8% !important;
    top: unset !important;
    font-size: 4rem;
    &.tick {
        background-color: #08a041;
        color: $color__white;
        box-shadow: inset 0 -3px 0 #006324, inset 0 3px 0 #06ee6c, 0 4px 3px $color__black;
        border-radius: 50%;
    }

    @media only screen and (max-width: $break-point--568) {
        font-size: 3rem !important;
        bottom: 18%;
        right: -2% !important;
    }

    @media only screen and (max-width: $break-point--480) {
        font-size: 2rem !important;
        bottom: 27%;
        right: -2% !important;
    }

    @media only screen and (max-width: $break-point--480) {
        font-size: 2rem !important;
        bottom: 25%;
        right: -2% !important;
    }

    @media only screen and (max-width: $break-point--320) {
        bottom: 30%;
    }
}

.progressive-ads-bonus--button {
    margin-top: .5em;
}

.progressive-ads-bonus__reward--item {
    @media only screen and (max-width: $break-point--900) {
        font-size: 1rem !important;
    }
    @media only screen and (max-width: $break-point--360) {
        font-size: .8rem !important;
    }
}