.tile__wrapper {
    &.highlighted {
        border: none;
        overflow: unset !important;
    }
    background: none !important;
}


.tile-counter {
    display: block;
    top: 1rem;
    right: 1rem;
}

.counterContainer {
    background-color: $black__transparent--05 !important;
}

.tile-counter.royal16--tile,
.tile-counter.knockoutTarot--tile {
    top: 1.5rem;
    right: 1.5rem;
}

.counterContainer.royal16--tile {
    border-radius: 10rem;
}

.counterContainer.knockoutTarot--tile {
    border-radius: 10rem;
}