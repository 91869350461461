.bc__button--flat {
  border: none;
  &:hover, &:active {
    border: none !important;
  }
  &:before {
    display: block !important;
  }
  &:disabled {
    border: none !important;
  }
}
