.custom-blitz-winners-info__container {
    @include default--container();
}

.custom-blitz-table--header,
.custom-blitz-winners-info--header {
    padding: 1rem;
    font-weight: $font-weight-extra-bold;
    font-size: $text__size--large;
    background-color: transparent !important;
}