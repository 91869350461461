.training-scroll-tile__container {
    background: url(imageUrl("lobby/training.png"));
    background-size: contain;
}

.training-scroll-tile__bclass {
    height: 4.5rem !important;
}

.training-scroll-tile__logo {
    display: none;
}

.training-scroll-tile__separator {
    display: block;
    width: 75%;
    height: 2px;
    margin: .3rem 0;    
    background-color: #000612;
}

.training-scroll-tile__title {
    font-weight: $font-weight-black;
    color: #000612;
    font-size: 1.8rem;
}

.training-scroll-tile__title_container {
    display: flex !important;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    align-items: center;
    width: 50%;
    display: flex !important;
    top: 0;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    align-items: center;
    width: 50%;
}

.training-scroll-tile__wrapper {
    justify-content: center;
    margin: 0 !important;
    margin-top: 40% !important;
}

.training-scroll-tile__restriction--cover {
    visibility: unset;
    .restriction-level--host {
        position: absolute;
        top: 42% !important;
        left: 2rem !important;
        margin: 0 !important;
    }
}

.training-scroll-tile__button {
    width: 80% !important;
    margin-bottom: 3.5rem !important;
}

.training-scroll-tile__description {
    font-size: 1.6rem !important;
    color: #000612;
    @media screen and (max-width: $break-point--1366) {
        font-size: 1.6rem !important;
    }
}

.training-scroll-tile__players-counter {
    background-color: $black__transparent--05 !important;
    color: $color--white;
    padding: 0.5rem !important;
    border-radius: 10rem;
    top: 42%;
    min-width: 6rem;
    right: 2rem;
}