.invite-friends__wrapper {
    @include default--container();
    height: 100%;
}

.invite-friends__banner {
    &:after {
        display: none;
    }
}

.invite-friends__title--wrapper {
    background-color: transparent;
}

.invite-friends__banner--img {
    background-image: url(imageUrl('invite-friends/referralChips.png'));
    background-size: contain;
}