$chat__nav-bar-container--bg-color: $main;
$chat__nav-bar-tab--inactive-bg-color: $color--bar;

.chats__component-container {
    background-color: desaturate(darken($main, 5%), 60%);
    z-index: $layer--3;
    border-radius: $border--radius;
    overflow: hidden;
}


.chats-nav-bar__container--nav {
    height: 3rem;
    &.isCustomSelecVisible {

        width: 10rem;
        @media only screen and (max-width: $break-point--440) {
            width: 6rem;
        }
    }
}

.chats-nav-bar__container {
    @extend %container--normal;
    border-radius: 0;
	background-color: #ede6e2;
}

.chats-nav-bar__container--button-close--bclass, .chats-nav-bar__button--back--bclass {
    color: #151835;
}

.chats-nav-bar__button--back--bclass {
    width: 3rem;
}

.chats-nav-bar__tab--single--paragraph {
    @extend %text;
}

.top-tabs__list-item--navigation__notification {
    @media only screen and (max-width: $break-point--320) {
        left: 10% !important;
    }
}

.chat__custom-select__option {
    display: flex;
    align-items: center;
    padding: .5rem;
    border-radius: $radius--default;

    > p {
      @extend %text;
      font-size: 1.8rem;
    }

    &:hover {
      cursor: pointer;
      background-color: lighten($main, 20%);
    }
}

.chat__custom-select__icon--lang {
    font-size:  $text__size--x-large;

    @media only screen and (max-width: $break-point--440) {
        display: none !important;
    }
}

.chat__top-tabs__bclass {
    @include top--tab();
    &.chat__top-tabs__selected {
        @include top--tab--selected();
    }
    margin: 0 !important;
}

.chat__top-tabs__scrollable--container {
    height: 5rem;
    display: flex;
    justify-content: center;
}

.chat__top-tabs__tab {
    height: 3rem;
}

.chats__component-container {
    box-shadow: 0 0 1.4rem 1rem rgba(5, 28, 39, 0.4);

    @media only screen and (max-width: $break-point--768) {
        box-shadow: none;
    }
}

.chat__chat-container {
    height: calc(100% - 8rem) !important;

    @media screen and (max-width: $break-point--768) {
        height: calc(100% - 9rem) !important;
    }
}

.chat__top-tabs__tab {
    padding: 0 .3rem;
}

.chat__top-tabs__button {
    height: 3rem !important;

    @media only screen and (max-width: 768px) {
        height: 3rem !important;
    }
}
