@use "sass:math";

$timer-container-margin: 10px;

:host {
    display: block;
    height: 100%;
    width: 100%;
    padding-top: $timer-container-margin;
}


.ranked-tiles__flag {
    position: relative;
}

$container-height: 16px;
$container-height-mobile: 15px;

$container-translateY: 6px;
$container-translateY-mobile: 5px;

.ranked--tiles__curved-container {
    display: none !important;
}

.ranked-games-leaderboard-list__title {
    background-color: transparent !important;
}
