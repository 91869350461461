/**************************
*     user info skin      *
**************************/
// Likes
.user-info__text--likes {
  > p { margin-left: 1rem; };
}

.user-info__button--extra-action {
  &.without__icon {
    display: block !important;
  }
  &.with__icon {
    display: none !important;
  }
}

.user-info__button--extra-action__bclass {
  padding: 0 2rem;
}