.gems-item--img-container {
    transform: scale(1.5);
    @media only screen and (max-width: $break-point--440) {
        transform: scale(1);
    }
}

.gems-item--container {
    display: flex;
    align-items: center;
}

.gems-item--img-container {
    flex: 1;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    >img {
        max-height: 100%;
        max-width: 100%;

        @media screen and (max-width:$break-point--480) {
            max-width: 100% !important;
        }
    }
}

.gems-bling {
    position: absolute;
    width: 60%;
    height: 50%;
    pointer-events: none;;
}

.gems-item__container--value {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.gems-item__text-value {
    font-size: 2rem !important;
}

.gems-item__text--bonus-value {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gems-item__text--bonus-value,
.gems-item__text--bonus-value-gems {
    font-size: 1.2rem !important;
    padding: 0 .5rem;
}

.img-size {
    padding: 0.5rem;
    height: 3.5em;
}

.gems-item__container__coin--shards--amount {
    display: flex;
    margin-top: .5rem;
    width: 100%;
    justify-content: center;
}

.gems-item__text-value__tier-bonus {
    &.old--price {
        position: relative;
        font-size: 1.4rem;
    }
    &.new--price {
        font-size: 2rem;
    }
}

.gems-item--img {
    position: unset !important;
    transform: translate(0%, 0%) !important;
    max-height: 100%;
    max-width: 100%;
}