.giftableProducts--donate-button-wrapper {
    background: rgba(0, 0, 0, 0.1);
}

.giftableProducts--title-text {
    @media screen and (max-width: $break-point--360) {
        font-size: 1.6rem;
    }
}

.giftableProduct--close-button {
    @media screen and (max-width: $break-point--360) {
        font-size: 1.6rem !important;
    }
}