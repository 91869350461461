.pf-tab__container--conversations {
    background-color: #ede6e2;
}

.pf-tab__container--chat {
    background-color: #ede6e2;
}

.pf-tab__container--messages {
    background-color: #ede6e2 !important;
    padding-top: 2rem;
    height: calc(100% - 9.25rem) !important;
    @media screen and (max-width: $break-point--768) {
        height: calc(100% - 6.25rem) !important;
    }
}

.pf-tab__username {
    height: 3.5rem !important;
    padding: .5rem 1rem;
    font-size: 1.2rem;
    background-color: #4b4b4bd1;
    color: #ffffff;
    border-radius: 10rem;
    width: unset !important;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
}

.pf-tab__search-bar__input {
    box-shadow: none;
    background-color: #f2e4d6 !important;
}