.leaderboards__tabs--scroll {
    background-color: $color--white;
}

.leaderboards__tabs--scrollbar {
    background-color: #04a3d4 !important;
}

.leaderboard__top-tabs__selected {
    color: #04a3d4 !important;
}

.leaderboard__top-tab__button {
    width: initial !important;
    @media screen and (max-width: $break-point--768) {
      height: 4rem !important;
    }
    @media screen and (max-width: $break-point--568) {
        width: initial !important;
    }
}

.leaderboard__top-tab__button-bclass {
    @include top--tab();
    width: 6rem;
    &.leaderboard__top-tabs__selected {
        @include top--tab--selected();
    }
}
