.shop-utility__box {
    height: 35rem!important;
}

@media only screen and (max-width: $break-point--580) {
    .shop-utility__box {
        height: 30rem!important;
    }
}

@media only screen and (max-width: $break-point--380) {
    .shop-utility__box {
        height: 25rem!important;
    }
}