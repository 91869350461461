.add__button-host {
    background-color: #1c4ec5;
}

.add-button_button {
    transition: none !important;
    color: #2160a8;
    &:hover {
        border: none !important;
        color: #9fcaf0;
    }
}
