.top-bar__container--notification {
    &.premium-gift {
        border: none !important;
    }
}


.top-bar__container--player-avatar {
    .username-container {
        border: none !important;
    }
}