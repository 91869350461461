.room__wrapper {
    background-color: #092a7b !important;
    border-radius: 1.5rem;
    border: none;
    overflow: hidden;
    @include container--tile(#041330, #3b65bc, #003093, #02113a);
    &.navigation-room_small-tile {
        .nav--box__image--wrapper {
            padding: 0 !important;
            &.tournament {
                @media only screen and (max-width: $break-point--568) {
                    left: 50%;
                    transform: translateX(-50%);
                    width: 130%;
                }
                .nav--room--box__image--icon {
                    top: 0;
                    transform: translate(-50%, 0px);
                    height: 85%;
                    background-position: bottom;
                    background-size: 100% auto !important;
                    background-image: url(imageUrl('lobby/tournament-room-cup_small.png'));
                    @media screen and (max-width: $break-point--1440) {
                        height: 75%;
                    }
                    @media only screen and (max-width: $break-point--380) {
                        background-size: 150% auto !important;
                    }
                }
            }
        }
        .nav--room--box__wrapper--texts {
            height: 25% !important;
        }
    }
    .nav--room--box__wrapper--texts {
        @include ribbon--gradient();
        display: flex !important;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        border-radius: 0 0 1.5rem 1.5rem;
        box-shadow: inset 0 3px 0 #000c40, inset 0 -4px 0 #015dea;
        text-shadow: 1px 0 0 #5c58b5, -1px 0 0 #5c58b5, 0 1px 0 #5c58b5, 0 -1px 0 #5c58b5;
        font-size: $text__size--large;
        margin-bottom: 0 !important;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: $break-point--1024) {
            font-size: $text__size--large;
            line-height: 20px;
        }
        @media only screen and (max-width: $break-point--980) {
            font-size: $text__size--normal;
            line-height: 16px;
        }
        @media only screen and (max-width: $break-point--480) {
            font-size: $text__size--small;
            line-height: 14px;
        }
    }
    &.custom {
        .nav--room--box__image--icon {
            background-size: cover !important;
            background-position: top;
            background-image: url(imageUrl('lobby/customRoom.png'));
        }
    }
    &.tournament {
        .nav--room--box__image--icon {
            background-size: contain !important;
            background-image: url(imageUrl('lobby/tournament-room-cup.png'));
        }
    }
}

// RAMADAN
// &.custom {
//     background-image: url(imageUrl('lobby/ramadan/room-bg-vip.svg'));
//     .nav--room--box__image--icon {
//         background-size: contain !important;
//         background-image: url(imageUrl('lobby/new-lobby-icons/vip-room-crown.png'));
//     }
// }
// &.tournament {
//     background-image: url(imageUrl('lobby/ramadan/room-bg-tournaments.svg'));
//     .nav--room--box__image--icon {
//         background-size: contain !important;
//         background-image: url(imageUrl('lobby/new-lobby-icons/tournament-room-cup.png'));
//     }
// }