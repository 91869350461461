.level-up-rewards--container {
    background-color: #00166d;
    border-bottom: 1px solid #004af5;

    &.ready-for-claim {
        background-color: #0045d6e8;
        border-bottom: 1px solid #00bffb;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 6px 1px #578cd3;
    }
}

.level-up-rewards-container--wrapper {
    background-color: #002acb;
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #0079ff;
    border: none;

    &.ready-for-claim {
        background-color: #0085ff;
        border: none;
        box-shadow: 0 2px 13px 0 #1f921e, 0 8px 13px 2px rgba(0, 0, 0, .5), 0 0 5px 4px #2db41b, inset 0 1px 1px 0 #00f1ff;
    }
}

.level-up-rewards-icon--wrapper {
    background-color: #031956;
}

.level-up-rewards-icon--wrapper-secondary {
    &.lock {
        background-image: linear-gradient(to bottom, #0047d1, #073ab6, #072d9b, #042182, #001669);
    }
}

.level-up-rewards-container--overlay {
    background-color: #01195ac7
}

@keyframes level-up-rewards-container-wrapper-animation {
    0% {
        background-color: #0028c4;
        transform: scale(1);
    }

    25% {
        background-color: #0042d5;
    }

    50% {
        background-color: #0058e4;
        transform: scale(1.03);
    }

    75% {
        background-color: #006ef2;
    }

    100% {
        background-color: #0083ff;
        transform: scale(1);
        box-shadow: 0 2px 9px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 5px 4px #2db41b, inset 0 1px 1px 0 #00f1ff;
    }
}

@keyframes level-up-rewards-container-animation {
    0% {
        background-color: #00166d;
    }

    25% {
        background-color: #042186;
    }

    50% {
        background-color: #072ca0;
    }

    75% {
        background-color: #0738ba;
    }

    100% {
        background-color: #0045d6e8;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 6px 1px #4c88da;
        border-bottom: 1px solid #00bffb;
    }
}

@keyframes level-up-rewards-container-wrapper-fade-out {
    0% {
        background-color: #0083ff;
        box-shadow: 0 2px 4px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 3px 2px #2db41b, inset 0 1px 1px 0 #00f1ff;
    }

    25% {
        background-color: #006ef2;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #0079ff;
    }

    50% {
        background-color: #0058e4;
    }

    75% {
        background-color: #0042d5;
    }

    100% {
        background-color: #0028c4;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #0079ff;
    }
}

@keyframes level-up-rewards-container-fade-out {
    0% {
        background-color: #0045d6e8;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), 0px 1px 0px 0px #578cd3;
    }

    25% {
        background-color: #0738ba;
        border-bottom: 1px solid #004af5;
        box-shadow: none;
    }

    50% {
        background-color: #072ca0;
    }

    75% {
        background-color: #042186;
    }

    100% {
        background-color: #00166d;
        box-shadow: none;
        border-bottom: 1px solid #004af5;
    }
}