.emoji-items__list {
    padding: 0.5rem 2rem 0.5rem !important;
    > .emoji-items__constainer-list {
        > .emoji-items__container--item--default {
            @include default--container();
        }
        > .emoji-items__container--item {
            @include special--container();
        }
    }
}