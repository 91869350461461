.level-up-progress-bar--container {
    background-color: #032058;
    box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, .5), 0 0 1px 2px #57b5c2;
}

.level-up-progress-bar--secondary-wrapper {
    background-color: #003cac;
}

.level-up-progress-bar--initial-wrapper {
    background-color: #010b26;
    box-shadow: 0 0 1px 1px #031b5c;
}