.rewarded-game-bonus--tile__content--container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &.scratchCard {
        background-image: url(imageUrl("lobby/ScratchCard.png"));
    }
    &.wheelOfFortune {
        background-image: url(imageUrl("lobby/wheeloffortune3.jpg"));
    }
}