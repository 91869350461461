.room__wrapper {
    background-color: #092a7b !important;
    .box__wrapper--texts {
        display: flex !important;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        @include ribbon--gradient();
        text-shadow: 1px 0 0 #321c4d, -1px 0 0 #321c4d, 0 1px 0 #321c4d, 0 -1px 0 #321c4d;
        font-size: $text__size--large;    
        border-radius: 0 0 1.5rem 1.5rem;
        box-shadow: inset 0 3px 0 #000c40, inset 0 -4px 0 #015dea;
        margin-bottom: 0 !important;
        justify-content: center;
        align-items: center;
    }
    &.small-tile {
        .box__image--wrapper {
            padding: 0 7rem;
            @media only screen and (max-width: $break-point--1366) {
                padding: 0 5rem;
            }
            @media only screen and (max-width: $break-point--1280) {
                padding: 0 3rem;
            }
            @media only screen and (max-width: $break-point--980) {
                padding: 0;
            }
        }
    }
    .room--box__image--icon {
        background-size: auto 125% !important;
        top: 65% !important;
        background-image: url(imageUrl('lobby/training-room-cards.png'));
    }
}

// RAMADAN
// .room__wrapper {
//     background-size: cover !important;
//     background-image: url(imageUrl('lobby/ramadan/room-bg-training.svg'));
// }