.level-up-rewards-img__level {
    font-size: 2.5rem;
}

@media screen and (max-width:$break-point--380) {
    .level-up-rewards-img__level {
        font-size: 2.3rem;
    }

    .level-up-rewards--level-silver {
        font-size: 2.2rem;
    }
}