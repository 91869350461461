@include font-face("Alexandria", $font-weight-light, normal) {
    src: url(fontUrl("Alexandria/Alexandria-Light.woff")) format("woff"),
        url(fontUrl('Alexandria/Alexandria-Light.ttf')) format("truetype");
}

@include font-face("Alexandria", $font-weight-normal, normal) {
    src: url(fontUrl("Alexandria/Alexandria-Regular.woff")) format("woff"),
        url(fontUrl('Alexandria/Alexandria-Regular.ttf')) format("truetype");
}

@include font-face("Alexandria", $font-weight-bold, normal) {
    src: url(fontUrl("Alexandria/Alexandria-Bold.woff")) format("woff"),
        url(fontUrl('Alexandria/Alexandria-Bold.ttf')) format("truetype");
}


@include font-face("Montserrat", $font-weight-black, normal) {
    src: url(fontUrl("Alexandria/Alexandria-Black.woff")) format("woff"),
        url(fontUrl('Alexandria/Alexandria-Black.ttf')) format("truetype");
}