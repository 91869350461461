.gallery--photo__delete-button,
.gallery--photo__report-button,
.gallery--photo__change-avatar-button {
    color: $color__white;
}

.gallery__container--inner-image {
    .gallery__container--add-button {
        background-color: #1c4ec5;
        .gallery__container--add-button__icon {
            color: #2160a8;
        }
        &:hover {
            border: none !important;
            .gallery__container--add-button__icon {
                color: #9fcaf0 !important;
            }
        }
    }
}
