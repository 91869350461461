.custom-blitz-table--container {
    @include default--container();
}


.custom-blitz-table__winners-info__footer {
    color: $color__white;
    border: none !important;
    background-color: #031955;
    font-weight: $font-weight-semibold;
    font-style: italic;
    box-shadow:0 9px 6px #010a21, 2px 0 0 #062b88, -2px 0 0 #062b88, inset 0 5px 6px #031543;
    padding-top: .5rem;
    border-radius: 0 0 1.5rem 1.5rem !important;
    > span {
        color: $color__chips !important;
        font-weight: $font-weight-bold;
    }
}

.custom-blitz-table--header {
    background-color: transparent !important;
}

.custom-blitz-table__state {
    font-weight: $font-weight-bold;
}