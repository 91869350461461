.container-leaderboard__container--tab {
    @include default--container();
    &:not(.selected) {
        @include dark--container();
        > p {
            color: transparentize($color__white, 0.4);
        }
    }
}

.container-leaderboard__container--leaderboard {
    @include default--container();
}

.container-leaderboard__container--users {
    padding: 0 5% !important;
    padding-top: 1rem;
    @media only screen and (max-width: $break-point--1280) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
    @media only screen and (max-width: $break-point--640) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
    @media only screen and (max-width: $break-point--580) {
        padding: 0 !important;
        padding-top: 1rem;
    }
    @media only screen and (max-width: $break-point--380) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
}

.container-leaderboard__container--user {
    width: 12.5% !important;

    @media only screen and (max-width: $break-point--1366) {
        width: 14% !important;
    }

    @media only screen and (max-width: $break-point--768) {
        width: 16.66% !important;
    }
    @media only screen and (max-width: $break-point--530) {
        width: 20% !important;
    }
    @media only screen and (max-width: $break-point--480) {
        width: 21% !important;
        margin-left: 2%;
        margin-right: 2%;
    }
    @media only screen and (max-width: $break-point--380) {
        width: 30.33% !important;
        margin-left: 1.5%;
        margin-right: 1.5%;
    }
}

.container-leaderboard--ranked-season-leaderboard-name{
    background-color: #0d43c3;
}