.fl__text--reward-value {
    font-size: 2rem !important;

    @media only screen and (max-height: $break-point--568) {
        font-size: $text__size--large;
    }
}

.fl__container--values {
    @include input--container();
}