.bottom-bar__container {
    height: 8rem !important;
    align-items: flex-start !important;

    @media only screen and (max-width: $break-point--768) {
        height: 6.5rem !important;
    }
}

.bottom-bar__container--button {
    top: 1rem;
    position: relative;
}

.bottom-bar__button-icon {
    .bc__button--icon-cmp {
        font-size: $text__size--x-large !important;
    }
    > p {
        margin-top: .5rem;
        @media only screen and (max-width: $break-point--568) {
            font-size: $text__size--smaller !important;
        }
    }
}

.bottom-bar__wrapper {
    box-shadow: inset 0 1px 1px #02143c, inset 0 4px 5px #0451cd;
}