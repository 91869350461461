$border-color: #630286;
$bg-color: #3f0355;
$border-width: 7px;
$border-width-mobile: 5px;
$border-completed-color: #81C14B;
$color-text: $color__white;
$color-value: $color__chips;

.game__index-wrapper {
    border: $border-width solid $border-color;
    border-radius: 50%;
    background-color: $bg-color;
    transition: border-color ease .4s;
    z-index: 1;

    &.selected {
        border-color: $color__chips;
    }

    &.completed {
        border-color: $border-completed-color;
    }

    @media screen and (max-width: $break-point--768 - 1), screen and (max-height: $break-point--568) {
        border-width: $border-width-mobile;
    }
}

.game__index--text {
    font-size: 2rem !important;
    font-weight: 900;
    color: $color_text;

    @media screen and (max-width: $break-point--768 - 1), screen and (max-height: $break-point--568) {
        font-size: 2rem !important;
    }
}

.game__index--value {
    font-size: 2.75rem !important;
    font-weight: 900;
    color: $color-value;

    @media screen and (max-width: $break-point--768 - 1), screen and (max-height: $break-point--568) {
        font-size: $text__size--x-large !important;
    }
}

.game__index--icon-wrapper {
    top: 0;
    right: 0;
    transform: scale(0) translate(0%, -10%);

    &.completed {
        transform: scale(1) translate(0%, -10%);
    }

    background-color: $border-completed-color;
    z-index: 2;

    @media screen and (max-width: $break-point--768 - 1), screen and (max-height: $break-point--568) {
        width: 2rem !important;
        height: 2rem !important;
    }
}

.game__index--icon {
    font-size: 2.75rem !important;

    @media screen and (max-width: $break-point--768 - 1), screen and (max-height: $break-point--568) {
        font-size: 1.4rem !important;
    }
}

.games__index--rays-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    animation: rays-show .4s ease forwards;
    z-index: 0;
}

.games__index--rays {
    @extend %rays-animation;
}

@keyframes rays-show {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}