.gallery-preview__wrapper {
    @include default--container();
}

.gallery-photo__container--single-photo-wrapper {
    background-color: #001256;
}

.gallery-preview__image--gallery-add-photo {
    background-color: #001256 !important;
}
