.pib__wrapper {
    @include default--container();
}

.pib__container--image {
    > img,
    .pib__svg-icon-cmp {
        max-width: 5rem !important;
        max-height: 5rem !important;
    }
}