.settings__top-tabs__bclass {
    width: 6rem;
    @include top--tab();
    width: 11rem !important;
    &.settings-popup__top-tabs__current {
        @include top--tab--selected();
    }
}

.settings-popup__list-item--navigation {
    margin-bottom: 1rem !important;
}