.game-box__container--box {
    border: none;
    border-bottom: none;
    border-top: .3rem solid #3967c5;
    background:#0a2a7b;
    padding: 0 !important;
    overflow: hidden;
    border-radius: 1.5rem !important;
    box-shadow: 2px 0 0 #0038a8, -2px 0 0 #0038A8, 0 2px 0 #001a58, 0 3px 3px #000000;
    &.final {
        background: #4400b2;
        border: none;
        border-radius: $radius--default;
        border-top: .3rem solid #551fc7;
        box-shadow: 2px 0 0 #4f03c4, -2px 0 0 #4f03c4, 0 2px 0 #4400b2, 0 3px 3px #000000;
        .box-footer {
            background-color: transparent !important;
            > .box-footer-text {
                background-color: $black__transparent--05;
            }
        }
    }
    > .box-title {
        background-color: transparent;
        width: 100% !important;
        margin: 0 !important;
        padding: 2.5rem 0;
        > p {
            font-weight: $font-weight-bold;
            color: $color__white;
            font-size: 1.9;
        }
    }
    > .box-content {
        > .versus-icon {
            > p {
                color: $color__white !important;
            }
        }
    }
    .box-footer {
        background-color: transparent !important;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem !important;
        height: initial !important;
        > .box-footer-text {
            padding: 1rem 2rem !important;
            background-color: $black__transparent--05;
            color: $color__white;
            font-weight: $font-weight-bold;
            border-radius: $container-border--radius;
        }
    }
}

.results-container {
    order: 3;
    display: flex;
    justify-content: space-around;
    margin: 0 1.5rem;
    color: $color__white !important;
}

.game__avatar__container {
    >.player_result {
        margin-top: 0;
        color: $color__white !important;
    }
}

.game__box-team-container{
    >.player_result {
        margin-top: 0;
        color: $color__white !important;
    }
}
