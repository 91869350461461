// Images
$background__pattern: url( imageUrl( 'backgrounds/bg.png?forceInline' ) );
$background__pattern: url( imageUrl( 'backgrounds/royal-palace.png?forceInline' ) ); // post ramadan season
$game__logo: imageUrl('game-logo/logo.svg');
$game__host: imageUrl('game-host/host.png');

:root {
    --background__pattern: #{$background__pattern};
    --game__logo: #{$game__logo};
    --game__host: #{$game__host};
    --item-reward-bg: #{url(imageUrl("free-rewards/container-reward-progressive.png"))};
}

// Global
$transition--default: .2s ease-in-out;
$radius--default: .8rem;
$container-border--radius: 2rem;
$before: 'before';
$after: 'after';

// Icons
$icon__size--default: 2.4rem;
$icon__args: $icon__size--default, $secondary, $before;

$text--shadow: 0 0 .5rem $black__transparent--05;
