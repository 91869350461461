$color-selected: $color__white;
$color-completed: #f29f32;

.progressive-ads__bar-wrapper {
    border: none;
    background-color: #00257e;
    box-shadow: 0 2px 1px #0767cd;
}

.progressive-ads__outer--baloon {
    border: none;
    background-color: #00257e;
    box-shadow: 0 1px 2px 0px #011034 inset, 0 2px 1px #0767cd;
}

.progressive-ads__inner--baloon {
    background-color: #1b0a20;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 1px #021c58;

    &.passed {
        width: 1.7em !important;
        height: 1.7em !important;
    }
    &.selected {
        background-color: $color-completed;
        box-shadow: 0px 1px 0px 0px #ffd7a2 inset;
    }
}

.progressive-ads__bar-inner {
    background-color: #1b0a20;
    box-shadow: 0 1px 1px #00257e;

    &.completed {
        box-shadow: none;
        background-color: $color-completed;
    }
}

.progressive-ads__number {
    color: #0767cd;
    font-weight: $font-weight-bold;
    font-style: inherit !important;

    &.selected {
        color: $color-selected;
    }
}

.progressive-ads__tick {
    font-size: 2em;
    color: $color-selected;
}
