.audio__content--time {
    color: $chat__message-text--color;
}

.audio__content--progress-class {
    background-color: #0D131F;
}

.audio__content--button-bclass {
    color: #0D131F !important;
}
