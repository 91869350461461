$border-color: #630286;
$bg-color: #630286;
$completed-color: #81c14b;
@use "sass:math";

$game-width: 80%; // Must be the same in the component class
$visible-games-count: 1; // Must be the same in the component class, currently $next-game-visible-percent works only with $visible-games-count = 1
$next-game-visible-percent: 0.5; // Must be the same in the component class
$margin-side-first-last: math.div((100% - ($game-width * $visible-games-count)),
    (($visible-games-count + 1) * 2));
$margin-side-between-games: $margin-side-first-last - $game-width * math.div(($visible-games-count * $next-game-visible-percent), 2);

.currency__baloon {
    color: $color__chips !important;

    @media screen and (max-width: $break-point--768),
        screen and (max-height: $break-point--568) {
        font-size: $text__size--small !important;
    }
}

.currency__baloon--value-config-bclass {
    color: $color__chips !important;
}

.currency__baloon--item-container-config-bclass {
    margin: 0 !important;
}

.currency__baloon--value-config-icon-bclass {
    @media screen and (max-width: $break-point--768),
        screen and (max-height: $break-point--568) {
        font-size: $text__size--normal !important;
    }
}

.games__progress--game-line {
    border: 2px solid $border-color;
    background-color: $bg-color;
    transition: border-color ease 0.4s, background-color ease 0.4s;

    &.completed {
        background-color: $completed-color;
        border-color: $completed-color;
    }
}

.games__progress--game-line-completed {
    border: 2px solid $completed-color;
    background-color: $completed-color;
}

.game__index--arrow {
    color: $color__chips;
    transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
    font-size: 2.3rem !important;
    animation: arrow-animation 0.9s ease infinite forwards;

    @media screen and (max-width: $break-point--768),
        screen and (max-height: $break-point--568) and (orientation: portrait) {
        transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
        font-size: 2.3rem !important;
    }
}

@keyframes arrow-animation {
    0% {
        transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
    }
    50% {
        transform: translate(-50%, 30%) rotate(90deg) scale(0.8, 0.7);
    }
    100% {
        transform: translate(-50%, 0px) rotate(90deg) scale(1, 1);
    }
}

.games__progress--game-index {
    max-width: 13rem !important;
    &.current {
        display: none !important;
    }
}

.games__progress--wrapper {
    margin: 0 $margin-side-between-games !important;
    width: $game-width !important;

    &.first {
        margin-left: $margin-side-first-last * 2 !important;
    }

    &.last {
        margin-right: $margin-side-first-last * 2 !important;
    }
}

.games__progress--icon__wrapper {
    left: 2 * $margin-side-first-last + math.div($game-width, 2) !important;
}

.games__progress--game-line-container {
    min-width: 2 * $margin-side-between-games + $game-width;
    &.first {
        margin-left: 2 * $margin-side-first-last + math.div($game-width, 2) !important;
    }
}

.games__progress--game-line-completed {
    &.first {
        margin-left: 2 * $margin-side-first-last + math.div($game-width, 2) !important;
    }
}
