.ranked-games-progress-avatar-wrapper {
    &.isCompleted {

        .ranked-games-progress-info-games--current,
        .ranked-games-progress-info-games--divider,
        .ranked-games-progress-info-games--required {
            color: $color--white;
        }
    }

    &.isClaimed {

        .ranked-games-progress-info-games--current,
        .ranked-games-progress-info-games--divider,
        .ranked-games-progress-info-games--required {
            color: $color--white;
            filter: brightness(0.7);
        }

        .ranked-games-progress-info--avatar {
            filter: brightness(0.7);
        }
    }
}

.ranked-games-progress-info--title {
    font-size: 2.4rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $color--white;
    text-shadow: 0 -1px 0 #c02e2c, 0 2px 2px #000000;
    padding-bottom: 2rem;
    line-height: 1.8;
    >span {
        color: $color--white;
        text-shadow: 0 -1px 0 #c02e2c, 0 2px 2px #000000;
    }
}