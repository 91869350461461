
.progress-bar__progress--container {
    border-width: 0 .05rem;
    border-style: solid;
    border-color: #021343;
    &.progress-good-bad-empty {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: #00B93E;
            }

            &:nth-last-child(2) {
                background-color: #C02126;
            }

            &:nth-last-child(3) {
                background-color: #F4A624 !important;
            }
        }
    }
    &.progress-classic {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: #79e9ea;
            }

            &:nth-last-child(2) {
                background-image: none !important;
                animation: none !important;
                background-color: #021343 !important;
            }
        }
    }
}