.shop-vip__container--vip-bottom-text-old-design {
    display: none;
}

.shop-vip__container--vip-bottom-text-redesign {
    display: block !important;
    margin: 5rem 0;
    .shop-vip__text--benefits {
        margin: 0 .5rem;
    }
    .shop-vip__icon--benefits {
        color: $color__white;
    }

    @media screen and (max-width: $break-point--568) {
        margin: 2rem 0;
    }
}

.shop-vip__button--benefits {
    width: fit-content;
}
