.winners__box-container {
    background-color: transparent !important;
    background-size: cover;
    background-position: center;
    border: none;
    border-bottom: 0.2rem solid #8333b6;
    .winners-ribbon-container {
        top: 0 !important;
        height: 10rem !important;
        background-color: transparent !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(-65%);
        @media only screen and (max-width: $break-point--980) {
            transform: translateY(-60%);
            background-size: contain;
            height: 13rem !important;
        }
    }
    &.first {
        background-image: none;
        border-bottom: none;
        background: linear-gradient(45deg, #DB8F21, #EBC047, #F0D788, #E4BF44, #E4BF44);
        border-top: 0.3rem solid #eed58c;
        box-shadow: 2px 0 0 #a47f21, -2px 0 0 #a47f21, 0 2px 0 #95671a, 0 3px 5px #000;
        .winners-ribbon-container {
            background-image: url(imageUrl("blitz-tournament/firstPlace.png"));
        }
    }
    &.second {
        background-image: none;
        border-bottom: none;
        background: linear-gradient(45deg, #ADADAD, #EBEBEB, #FFFFFF, #EBEBEB, #ADADAD);
        border-top: .3rem solid #fff;
        box-shadow: 2px 0 0 #a6a5a5, -2px 0 0 #A6A5A5, 0 2px 0 #757575, 0 3px 6px #000;
        .winners-ribbon-container {
            background-image: url(imageUrl("blitz-tournament/secondPlace.png"));
        }
    }
}

.winners-ribbon {
    height: 0 !important;
}

.winners__box-container {
    border-radius: 1.5rem !important;
}

.winners-prize {
    width: initial !important;
    height: initial !important;
    padding: 1rem 2rem;
    background-color: $black__transparent--05;
    border-radius: .8rem;
    > .winners-prize-text {
        font-size: $text__size--large !important;
        font-weight: $font-weight-bold;
        color: $color__white;
    }
    > .winners-prize-chips {
        font-size: $text__size--large !important;
        color: $color--chips;
        font-weight: $font-weight-bold;
    }
}

.winner_box-team-player {
    border: none !important;
}

.winners-avatars {
    padding-top: 2rem;
    @media only screen and (max-width: $break-point--980) {
        padding-top: 3rem;
    }
}