/*****************************
* Popup container viptarneeb *
*****************************/

%platform-skinned-popup {
    background: #031955;
    background-size: 15rem;
}

.popup-container--platform-skinned-popup {
    @extend %platform-skinned-popup;
}

// Styles
.popup-container__wrapper {
  z-index: $layer--3;

  &.has-backdrop {
    @extend %container--dimmer;
  }
}

@keyframes rotateRays {
    from { transform: rotateZ(0deg) }
    to { transform: rotateZ(360deg) }
}

.popup-container__container--rays {
    &:before {
        background-image: var(--rays-animation);
        background-repeat: no-repeat;
        background-position: center;
        animation: rotateRays 10s linear infinite;
        will-change: transform;
    }
}

.popup-container__wrapper--content {
    border-radius: 2.5rem;
    @extend %platform-skinned-popup;
}