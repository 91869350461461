.top-bar__wrapper--button-icon {
  &.settings {
    &.oldDesign {
      display: none !important;
    }

    &.newDesign {
      display: block !important;
      order: 1;
      margin-left: .5rem;
    }
  }
}

.top-bar__container--challenges {
  width: initial !important;
}

.top-bar__container--info-middle {
  padding-left: 2rem;
}

.top-bar__wrapper {
  height: 7rem !important;
  box-shadow: inset 0 -1px 1px #02143c, inset 0 -4px 5px #0451cd;
  @media only screen and (max-width: $break-point--1366) {
    height: 7rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
    height: 5rem !important;
  }
}
