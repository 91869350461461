/*****************************
*    Default button styles   *
*****************************/

// Placeholders
%button {
  position: relative;
  display: block;
  user-select: none;
  border: none;
  color: $button__text--color;
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-size: inherit;
  &:focus {
    outline: 0;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

// Helper functions
// Get desired color from buttons color map
@function get-color( $color, $shade: 'base', $map : $button-colors-map ) {
  $parent: map-get($map, $color);
  @return map-get($parent, $shade);
}

// Main button decorator
@mixin button_decorator( $type ) {
  @if ($type == $button--white) {
    background-color: $color--white;
  } @else if ($type != $button--transparent) {
    $border-top: get-color($type, border-top);
    $background-color: get-color($type, background);
    $border-bottom: get-color($type, border-bottom);
    $fontColor: get-color($type, fontColor);
    $background-active: get-color($type, background-active);
    $border-top-active: get-color($type, border-top-active);
    $border-bottom-active: get-color($type, border-bottom-active);
    $box-shadow: get-color($type, box-shadow);
    background: $background-color;
    border-radius: 10rem;
    box-shadow: $box-shadow;
    > p {
      color: $fontColor;
    }
    &:before {
      border-top: $border-top;
      content: "";
      background: none;
      border-radius: inherit;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &:after {
      border-bottom: $border-bottom;
      content: "";
      background: none;
      border-radius: inherit;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    @include touch-hover() {
      background: $background-active;
      transition: background $transition-value ease-in-out;
      &:before {
        transition: border-top $transition-value ease-in-out;
        border-top: .3rem solid $border-top-active;
        content: "";
      }
      &::after {
        transition: border-bottom $transition-value ease-in-out;
        border-bottom: .3rem solid $border-bottom-active;
        content: "";
      }
    }


    &:disabled {
      background: $background-active;
      border-top: none;
      border-bottom: none;
      filter: brightness(0.6) grayscale(0.4);
      transition: none;
      box-shadow: $box-shadow;
      > p {
        text-shadow: none;
      }

      &:before {
          background: none;
          border-top: $border-top-active;
      }
      &:after {
          background: none;
          border-bottom: $border-bottom-active;
      }
    }
  } @else {
    border: none;
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }

  }

}

// Button that contains only text
@mixin button--text( $type ) {
  @include button_decorator($type);
  > p {
    @extend %text;
    position: relative;
    display: block;
    font-size: $text__size--large;
    line-height: 108% !important;
    font-weight: 600;
    text-align: center;
    text-transform: initial;
    margin: 0 .5rem;
    
      word-break: break-word;
    @if ($text-shadow-enabled == true) {
      text-shadow: $button__shadow--text;
    }
  }

  @if ($type == $button--transparent) {
    > p {
        text-decoration: underline;
        text-decoration-style: solid;
    }

    &:disabled {
      > p {
          opacity: 0.5;
      }
    }
  }

  > .bc__spinner--cmp {
      width: 2em !important;
      font-size: $text__size--large !important;
  }
  @media only screen and (max-width: $break-point--768) {
    > p {
      font-size: $text__size--normal;
    }

    > .bc__spinner--cmp {
        font-size: $text__size--normal !important;
    }
  }
}

// Button that contains only icon
@mixin button--icon( $icon, $type, $icon-size: 2.4rem ) {
  @include button_decorator($type);
  font-size: inherit;
  @if ($type == $button--transparent) {
    background-color: transparent;
  }
}

// Button that contains text and icon
@mixin button--icon-text( $icon, $type) {
  @include button_decorator($type);
  position: relative;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
    > p {
    @extend %text;
    position: relative;
    display: block;
    font-size: $text__size--large;
    line-height: 108% !important;
    font-weight: 600;
    text-align: center;
    text-transform: initial;
    @if ($text-shadow-enabled == true) {
      text-shadow: $button__shadow--text;
    }
  }

  @if ($type == $button--transparent) {
    &:disabled {
      > p {
          opacity: 0.5;
      }
    }
  }

  > .bc__spinner--cmp {
    width: 2em !important;
    font-size: $text__size--large !important;
  }

  @media only screen and (max-width: $break-point--768) {
    > p {
      font-size: $text__size--normal;
    }
    > .bc__spinner--cmp {
        font-size: $text__size--normal !important;
    }
  }
  @media only screen and (max-width: $break-point--380) {
    padding: 0 1rem;
    > p {
      font-size: $text__size--small;
    }

    > .bc__spinner--cmp {
        font-size: $text__size--small !important;
    }
  }
}

// Button with no text only image from url
@mixin button--image( $type ) {
  @include button_decorator($type);
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
  }
}

// Button that contains text and image
@mixin button--image-text($type) {
  @include button_decorator($type);
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 2.5rem;
    position: relative;
    margin-right: 0.75rem;
  }
  > p {
    @extend %text;
    position: relative;
    display: block;
    font-size: $text__size--large;
    line-height: 108% !important;
    font-weight: 600;
    text-align: center;
    text-transform: initial;
    color: $color__white;
    margin: 0;
    @if ($text-shadow-enabled == true) {
      text-shadow: $button__shadow--text;
    }
    @if ($type == $button--white) {
      color: #757777;
      text-shadow: none;
    }
  }
  @media only screen and (max-width: $break-point--768) {
    > p {
      font-size: $text__size--normal;
    }
  }
}
