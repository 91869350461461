.team-stats__container--game-stats {
    @include default--container();
}

.team-stats__button--replay {
    border: none !important;
    background-color: transparent !important;
    &.old-design {
        display: none;
    }
    &.new-design {
        display: block;
    }
}
