.friends-dropdown__top-tabs__bclass {
    width: 6rem;
    @include top--tab();
    width: 11rem !important;
    &.friends-dropdown__top-tabs__current {
        @include top--tab--selected();
    }
}

.friends-dropdown__list-item--navigation {
    margin-bottom: 1rem !important;
}