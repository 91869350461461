/*******************************
*   Default container styles   *
*******************************/
$default--border-radius: 2rem;

$special--background: #591ab7;
$special--border-top: #b234ff;
$special--border-side: #411287;
$special--border-bottom: #210a44;
$special--shadow-bottom: #16072d;
// Mixins
@mixin container_constructor($type: $container--default, $cover: 'default', $color: 'transparent', $stroke: .1rem, $shadow: false, $radius: 0) {
  @if ( $type != $container--dark ) {
  } @else {
    background-color: dark( $color ) !important;
  }
  @if ( $color != false ) {
    background-color: $color;
  } @else {
    background-color: transparent;
    border-radius: $radius;
    @if ( $shadow == true ) {
        box-shadow: $container__shadow--inset;
    }
  }
  @if ( $shadow == true ) {
    box-shadow: $container__shadow;
  }
}

@mixin container(
  $background--color,
  $border--top,
  $border--side,
  $border--bottom,
  $shadow--bottom
) {
  border: none;
  background-color: $background--color;
  border-top: 2px solid $border--top;
  box-shadow: 0 2px 0 $border--bottom, 2px 0 0 $border--side, -2px 0 0 $border--side,0 9px 6px $shadow--bottom;
  border-radius: $default--border-radius;
}

@mixin default--container() {
  @include container(#00257e, #0767cd, #02309c, #021c58, #011034);
}

@mixin dark--container() {
  @include container(#031442, #0d2461, #072271, #021c58, #011034);
}

@mixin accent--container() {
  @include container(#1c4ec5, #437aff, #3962c3, #274589, #011034);
}

@mixin special--container() {
  @include container(#591ab7, #b234ff, #411287, #210a44, #16072d);

}

@mixin container--tile($background-color, $border-top, $shadow-side, $shadow-bottom) {
    border-top: 4px solid $border-top !important;
    background-color: $background-color;
    box-shadow: inset 0 -4px 0 $shadow-bottom, inset 4px 0 0 $shadow-side,inset -4px 0 0 $shadow-side !important;
    border-radius: 1.5rem;
}

@mixin top--tab() {
  background-color: #005f95 !important;
  height: 4rem !important;
  margin: .5rem;
  border-radius: 10rem;
  color: #acb9b6 !important;
  &:before {
    border-top: .3rem solid #0073bd;
    content: "";
    background: none;
    border-radius: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &:after {
    border-bottom: .3rem solid #004276;
    content: "";
    background: none;
    border-radius: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@mixin top--tab--selected() {
  background-color: #1893cc !important;
  color: $color--white !important;
  &:before {
    border-top: .3rem solid #04a3e3;
    content: "";
    background: none;
    border-radius: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &:after {
    border-bottom: .3rem solid #00709e;
    content: "";
    background: none;
    border-radius: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@mixin ribbon--gradient() {
  background: linear-gradient(90deg,#010b1d,#0044da 4%,#092a7b 13%,#031955 50%,#092a7b 87%,#0044da 96%,#010b1d);
  border-radius: 0 0 1.5rem 1.5rem;
  box-shadow: inset 0 3px 0 #000c40, inset 0 -4px 0 #015dea;
}

@mixin input--container() {
  background: #021243;
  box-shadow: inset 0 0 15px #010a25;
  border: none !important;
}

// Placeholders
%form__container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 40rem;
  border: .1rem solid $accent--1;
  background-color: $secondary;
  border-radius: $radius--default;
  @media only screen and (max-width: $break-point--1920) {
    max-width: 45rem;
  }
  @media only screen and (max-width: $break-point--1366) {
    max-width: 36rem;
  }
  @media only screen and (max-width: $break-point--768) {
    max-width: 30rem;
  }
  @media only screen and (max-height: $break-point--480) {
    max-height: 98vh;
  }
  .form__content {
    @include touch-scroll();
    padding: 10% 2rem 2rem;
    @media only screen and (max-width: $break-point--768) {
      padding: 10% 1rem 1rem;
    }
    @media only screen and (max-height: $break-point--640) and (orientation: landscape ) {
      display: block;
      height: 85%;
      overflow-y: scroll;
    }
    @media only screen and (max-height: $break-point--530) and (orientation: portrait ) {
      overflow-y: scroll;
    }
    @media only screen and (min-height: $break-point--640) and (orientation: landscape ) {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    @media only screen and (min-height: $break-point--530 ) and (orientation: portrait ) {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    @media only screen and (max-height: $break-point--530)  {
      height: 85%;
    }
    @media only screen and (max-height: $break-point--360)  {
      height: 80%;
    }
  }
}

// Container with gradient cover
%container--normal {
  @include container_constructor( $container--normal, $container__cover, $secondary);
  border-radius: $radius__default;
  box-shadow: $container__shadow;
}

%container--dark {
  @include container_constructor($container--dark, 'default', $secondary, .1rem, false);
}


%container--aside {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100% - 11rem);
  max-width: $max-content-width;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  @media only screen and (max-width: $break-point--1366) {
    height: calc(100% - 10.1rem);
  }
  @media only screen and (max-width: $break-point--768) {
    height: calc(100% - 7.5rem);
  }
}

// Main Dimmer ( cover element )
%container--dimmer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black__transparent--08;
  z-index: $layer--3;
  overflow-y: auto;
  overflow-x: hidden;
}

// Used in containers like popup, login, register and settings
%container--bordered {
  position: relative;
  border: 3px solid $accent--1;
  background-color: $secondary;
  border-radius: $radius--default;
  min-height: 28rem;
  overflow: hidden;
  .container__title {
    position: relative;
    background-color: $dark-secondary;
    order: 1;
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      @include separator( orange );
    }
    @media only screen and (max-height: $break-point--440) {
      height: 20%;
    }
  }
  .container__button--close {
    position: absolute!important;
    width: /*3.5*/4rem;
    height: /*3.5*/4rem;
    top: 1rem;
    right: /*1*/2rem;
    padding: 0!important;
    margin: 0!important;
    min-height: auto!important;
    z-index: 2;
    @media only screen and (max-width: $break-point--768) {
      //top: .5rem;
      //right: .5rem;
      height: 3.5rem;
    }
    @media only screen and (max-height: $break-point--440) {
      top: 2%;
    }
  }
}


%shop-box {
  width: 33.33%;
  height: 37rem;
  padding: 2rem 1.5rem;
  max-width: 24rem;

  @media only screen and (max-width: $break-point--568) {
      height: 30rem;
      padding: 2rem 1rem;
  }

  @media only screen and (max-width: $break-point--380) {
      width: 50%;
      padding: 2rem 1rem;
  }
}

%shop-wrapper {
  overflow: visible;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  align-content: flex-start;
  justify-content: center;
  touch-action: pan-y !important;
  max-width: 90rem;
  margin: 0 auto;

  @media only screen and (max-width: $break-point--380) {
      max-width: 30rem;
  }
}
