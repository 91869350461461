.pto__container--contents {
  padding: 0 !important;
}

.promo-tournaments-options__top-tabs__bclass {
  width: 11rem;
  @include top--tab();
  &.top-tabs__selected {
      @include top--tab--selected();
  }
}

.promo-tournaments-options__top-tabs__container {
  background-color: transparent;
}

.promo--tournaments__top-tabs__list-item--navigation {
  height: 4rem;
  @media only screen and (max-width: $break-point--768) {
    height: 4rem !important;
  }
}