.shop__top-tabs__bclass {
  @include top--tab();
  &.shop__top-tabs__bclass__selected {
    @include top--tab--selected();
  }
}

.shop__tabs--redesign {
  display: initial !important;
  margin: 0 1rem;
  .tab {
    padding: .5rem 0;
  }
}