.gifts-tab__text--premium-gifts-label {
    padding: 1rem 0 2rem;
}

.gifts-tab__container--gift__default, .gifts-tab__container--gift__premium {
    width: 14.6%;
    margin: 1%;
    @media only screen and (max-width: $break-point--568) {
        width: 18%;
    }
    @media only screen and (max-width: $break-point--440) {
        width: 21%;
        margin: 2%;
    }
    @media only screen and (max-width: $break-point--320) {
        width: 27.33%;
        margin: 3%;
    }
    &.in-game {
        width: 6.333%;
        @media only screen and (max-width: $break-point--1366) {
            width: 8%;
        }
        @media only screen and (max-width: $break-point--1024) {
            width: 12.28%;
        }
        @media only screen and (max-width: $break-point--568) {
            width: 18%;
        }
        @media only screen and (max-width: $break-point--440) {
            width: 21%;
            margin: 2%;
        }
        @media only screen and (max-width: $break-point--320) {
            width: 27.33%;
            margin: 3%;
        }
    }
}