.daily-bonus--tile__content--container {
    
    background-image: url(imageUrl("lobby/DailyReward.png"));
    @include container--tile(transparent, #ea61f2, #9701a8, #540859);
    &.progressive-videos {
        background-image: none !important;
        @include container--tile(#6e097b, #cd68de, #8d00a8, #560061);

        .daily-bonus--tile__icon {
            width: 100%;
            height: 100%;
            background-image: url(imageUrl("lobby/DailyRewards-Icon.png"));
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display: block !important;
        }

        .daily-bonus--tile__text--container {
            background: linear-gradient(90deg,#370141,#be00dc 4%,#750482 13%,#4d0355 50%,#750482 87%,#be00dc 96%,#370141);
            border-radius: 0 0 1.5rem 1.5rem;
            box-shadow: inset 0 3px 0 #b602e8, inset 0 -4px 0 #28003e;
            position: absolute;
            bottom: 0;
            height: 40%;
            justify-content: center !important;
        }

        .daily-bonus--tile__text {
            height: initial !important;
        }
    }
}

.daily-bonus--tile__text--container {
    background-color: transparent;
    justify-content: right !important;
}

.daily-bonus--tile__button {
    margin: 1rem !important;
    height: 3.5rem !important;
    @media only screen and (max-width: $break-point--768) {
        height: 3rem !important;
    }
}

.daily-bonus--tile__timer {
    padding: 1.5rem;
    height: initial !important;
}

