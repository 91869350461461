.profile__top-tabs__bclass {
    width: 6rem;
    @include top--tab();
    &.top-tabs__selected {
        @include top--tab--selected();
    }
}

.profile__container--router-outlet {
    @include scrollbar();
    @include touch-scroll();
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.profile__top-tabs {
    margin: 0 1rem;
}

.profile-top-tabs__list-item--navigation {
    margin: 1rem auto !important;
    height: initial !important;
}

.profile__top-tabs__bclass__notification__button {
    height: initial !important;
}

.profile__dot--icon {
    border-color: transparent !important;
}