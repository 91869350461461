.ranked-games-leaderboard-info-box {
    box-shadow: none !important;
    border-radius: $border--radius;
    border: none !important;
}

.ranked-games-leaderboard-info-box__title--text {
    color: $color--white;
    text-shadow: 0 1px 0px #000;
    font-weight: $font-weight-bold;
}

.ranked-games-leaderboard-info-box__title {
    background-color: transparent;
    text-transform: uppercase;
}

.ranked-games-leaderboard-info-box__delimiter {
    display: none;
}

.ranked-games-leaderboard-info-box__value {
    background-color: transparent;
    text-shadow: 0 3px 3px #000;
    color: white;
}

.ranked-games-leaderboard-info-box__wrapper {
    background-color: #04113f;
}