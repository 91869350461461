.product-flag__with--label {
    background: #ca4a00;
    box-shadow: 2px 0 0 #e88923, -2px 0 0 #e88923, 0 2px 0 #733000;
    border-top: 2px solid #ff722e;
    border-radius: 1rem !important;
}

.shop-item-product-flag_daily-deal {
    margin-right: 4.5rem;
    white-space: nowrap;

    >span {
        display: flex;
        align-items: center;
        height: 100%;
        width: 5rem;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 !important;
        background-color: #ee4d00;
        justify-content: center;
        box-shadow: -1px 0 10px #6d2401;
    }
}

.item-box__label-with-elevated-price {
    border: none !important;
    background-image: linear-gradient(to bottom, #0047d1, #073ab6, #072d9b, #042182, #001669) !important;
    box-shadow: 0 -1px 0 0 #0067ea, 0px 2px 6px 0px black !important;
    margin-bottom: 0.5rem;
}