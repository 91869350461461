.ranked-games-progress {
    border-radius: $container-border--radius;
}

.ranked-games-progress__backdrop {
    border-radius: 1.5rem;
    background-color: transparent;
}

.ranked-games-progress__wrapper {
    border: 4px solid #c39540;
    border-radius: 2rem;
}

.ranked-games__all-claimed-text {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 2.4rem;
    color: $color--white;
    text-shadow: 0 -1px 0 #c02e2c, 0 2px 2px #000000;
    height: 5rem;
    margin-bottom: 1.75rem;

    @media screen and (max-width: $break-point--568) {
        font-size: 1.6rem;
        height: 3rem;
        margin-bottom: 0.75rem;
    }
}