.personal-notes--comments {
    background-color: #00257e;
    border-top: 1px solid #0767cd;
    box-shadow: 0 2px 0 #021c58, 2px 0 0 #02309c, -2px 0 0 #02309c, 0 3px 3px #011034;
    color: #fff;
    margin: 1.5rem auto;
}

.personal-notes__simple-message-input {
    background-color: #031955;
}
