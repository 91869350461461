.mini-game-double-up__container {
    background-color: #031955;
}

.mini-game-double-up__won--text {
    text-shadow: -2px -2px 0 #f5a724, 2px -2px 0 #f5a724, -2px 2px 0 #f5a724, 2px 2px 0 #f5a724;
    color: $color__white;
}

.mini-game-double-up__image {
    height: 14rem !important;
}

.mini-game-double-up__reward__amount {
    font-weight: $font-weight-black;
    font-size: $text__size--x-large;
    text-shadow: 1px 3px 1px #010b27;
    font-style: italic;
}

.mini-game-double-up__bclass>p {
    font-size: 1.3rem;
}