.chat-invite-content__info-box {
    background-color: transparent !important;
}

.chat-invite-content__header {
    height: 12rem !important;
    > .chat-invite-content__image {
        background-color: #4d464145!important;
        border-radius: 0.8rem;
        max-height: 70% !important;
        padding: 1rem;
    }
}

.chat-invite-content__info-box--text {
    color: #0D131F;
}

.chat-invite-content__currency-label {
    color: #c57b2a;
}