.shop-bank-transfer__container--info {
    .shop-bank-transfer__text-list {
        width: 90%;
        margin: 1rem 5%;
    }
}

.shop-text-list__container--benefits-label {
    padding: 0.5rem 4rem !important;

    @media only screen and (max-width: $break-point--568) {
        padding: .5rem 1rem !important;
    }
}
