.btt__image {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(imageUrl("lobby/tournament.png"));
  background-position: 50% 100%;
  height: 30%;
  width: 100%;
  border-radius: 2rem 2rem 0 0;
}

.btt__container {
  height: 70%;
  border-radius: 0 0 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.btt__wrapper {
  &.royal8 {
    .btt__marble {
      background: linear-gradient(90deg, #010B1D , transparent 4%, transparent 96%, #010B1D);
    }
    .btt__image {
      box-shadow: inset -3px 0px 0 #0037a4, inset 3px 0px 0 #0037a4, inset 0 3px 0 #3964b7;
      background-color: #092a7b;
    }
    .btt__container {
      background: linear-gradient(90deg,#002A87,#002A87 2%,#031955 13%,#031955 50%,#031955 87%,#002A87 98%,#002A87);
      box-shadow: inset 0 5px 0 #000A3E, inset 0 -4px 0 #025DE8;
    }
  }
  &.royal16 {
    .btt__marble {
      background: linear-gradient(90deg, #1D011B , transparent 4%, transparent 96%, #1D011B);
    }
    .btt__image {
      box-shadow: inset -3px 0px 0 #a80096, inset 3px 0px 0 #a80096, inset 0 3px 0 #8e1c81;
      background-color: #7b096a;
    }
    .btt__container {
      background: linear-gradient(90deg,#8C007B,#8C007B 2%,#550348 13%,#550348 50%,#550348 87%,#8C007B 98%,#8C007B);
      box-shadow: inset 0 5px 0 #38003E, inset 0 -4px 0 #9002E8;
    }
  }
  border-radius: 2rem;
}

.btt__container--room-icon {
  display: none;
}

.btt__container__name {
  >.tile__text--name {
    font-size: 5rem !important;
    width: 80% !important;
    margin: 0 auto;
    line-height: initial !important;
    @media only screen and (max-width: $break-point--680) {
      width: 100% !important;
    }
    @media only screen and (max-width: $break-point--480) {
      font-size: 3rem !important;
    }
  }
}

.btt__container__description {
  >.tile__text--description {
    font-size: 2.4rem !important;
    line-height: initial !important;
  }
}

.entry-fee__container {
  font-size: 2.4rem !important;
  font-weight: $font-weight-bold;
}

.btt__container--middle {
  flex: initial !important;
}

.btt__container--top-bar {
  justify-content: space-around;
}

.btt__container--place {
  flex: initial !important;
}

.btt__text--place {
  font-size: inherit !important;
  text-transform: uppercase;
}

.btt__text--place-value-icon {
  font-size: inherit !important;
}

.btt__container--top-bar {
  background-color: transparent !important;
}

.btt__text--place-value {
  font-size: inherit !important;
}

.btt__info-wrapper {
  font-size: 2rem;
}

.btt__marble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  border-radius: 0 0 2rem 2rem;
}