.leaderboard-item__score {
    font-weight: $font-weight-bold !important;

    > number {
        @include chip-sign($text__size--small);
    }
    &.matchmaking {
        > number {
            @include icon($star, $text__size--small, $accent--1, $before);
        }
    }
    &.best {
        > number {
            @include icon($star, $text__size--small, $accent--1, $before);
        }
    }
    &.likes {
        > number {
            @include icon($heart, $text__size--small, $accent--1, $before);
            // &:before {
            //     content: $heart !important;
            //     left: -1.8rem;
            // }
        }
    }
    &.richest,
    &.earnedchips {
        > number {
            font-size: 1.2rem;
            @include chip-sign($text__size--small);
        }
    }
    &.gems {
        > number {
            @include icon($icon-gems, $text__size--small, $accent--1, $before);
        }
    }

    &.clubs {
        > number {
            @include icon($club-points, $text__size--small, $accent--1, $before);
        }
    }

    &.clubmembers {
        > number {
            @include icon($club-xp, $text__size--small, $accent--1, $before);
        }
    }
}