.friends__container--friends-users__list {
  width: 100% !important;
  margin: 2rem 0 !important;
  min-height: 10% !important;
  padding: 0 6.5rem !important;
  @media only screen and (max-width: $break-point--1366) {
      padding: 0 6rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
      padding: 0 1rem !important;
  }
}

.friends__container--invite-fr {
  padding-top: 2rem !important;
}