.daily-reward--title {
    padding-top: .6rem !important;
    color: #0d214b;
    font-weight: $font-weight-normal;
}

.daily-reward {
    background-color: #f4e4d4;

    &:not(.current) {
        box-shadow: inset 0 2px 0 #fef2e4, inset 0 -2px 0 #724930, inset 2px 0 0 #dc855c, inset -2px 0 0 #dc855c;
    }

    &.checked {
        background-color: #f4e4d4;
    }

    &.registerAdvent {
        @include default--container();
        box-shadow: 0 2px 0 #021c58, inset 2px 0 0 #02309c, inset -2px 0 0 #02309c, 0 9px 6px #011034;
        @include item-reward((
            inner-content-padding: 0,
            img-wrapper-height: auto,
            aspect-ratio-width: 1,
            aspect-ratio-height: 1,
            item-img-width: 50%,
            item-table-img-width: 25%,
            item-reward-justify-content: space-evenly
        ));

        .daily-reward--title {
            color: white;
        }

        &.current {
            background-color: #591ab7 !important;
            box-shadow: 0 0 12px 4px $color--selected, 0 0 0px 2px $color--selected, 0 2px 0 #210a44,2px 0 0 #411287,-2px 0 0 #411287,0 9px 6px #16072d;

            &.highlighted {
                box-shadow: 0 0 12px 4px $color--selected, 0 0 0px 2px $color--selected, 0 2px 0 #210a44,2px 0 0 #411287,-2px 0 0 #411287,0 9px 6px #16072d;
            }
        }

        &.checked {
            opacity: 0.4;
            filter: brightness(0.5);
        }

        &.highlighted {
            background-color: #591ab7 !important;
            box-shadow: 0 2px 0 #210a44,2px 0 0 #411287,-2px 0 0 #411287,0 9px 6px #16072d;
            border-top: 2px solid #b234ff;
        }
    }
}

.daily-reward-container__reward {
    background-color: #c38560;
    padding: .2rem;
    border-radius: 1rem;
    text-shadow:
        -1px -1px 0 #865332,
        1px -1px 0 #865332,
        -1px 1px 0 #865332,
        1px 1px 0 #865332;

    @media screen and (max-width: $break-point--320) {
        max-width: 85%;
    }
}