/**********************************
*          Home page skin         *
**********************************/

// Styles

.home__wrapper {
    overflow-y: auto;
    @include scrollbar();
    overflow-x: hidden;
}

.home__image--logo {
    @include background-image(imageUrl("/platform-logos/baloot.svg"), auto, 100%, center, center);
    height: 100% !important;
}

.home__image--game-element {
    display: none;
}

// Terms an privacy
.home__text--terms-privacy {
    @extend %text;
    color: $color__white;

    .twp__text {
        display: block !important;
    }
}

.home__link--terms {
    @extend %link;
}

.home__link--privacy {
    @extend %link;
}

.home__custom-select__icon {
    width: 2rem;
    margin-right: 1rem;
}

.home__custom-select__option {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: $radius--default;

    > p {
        @extend %text;
        font-size: $text__size--large;
    }

    &:hover {
        cursor: pointer;
        background-color: lighten($main, 20%);
    }
}

.home__image--logo {
    max-width: 70%;
    max-height: 80%;
    background-position: top;
    background-size: contain;
    margin-bottom: 2rem;
}