.custom-phrases__list {
    > .custom-phrases__constainer-list {
        > .custom-phrases__container--item-default {
            @include default--container();    
            
            &:hover {
                background-color: light(#00257e);
            }
            &.vip {
                @include accent--container();
                &:hover {
                    background-color: light(#1c4ec5);
                }
                &.player-non-vip {
                    @include dark--container();
                    &:after {
                        border-radius: 2rem;
                    }

                    &:hover {
                        @include dark--container();
                    }
                }
            }
        }
        > .custom-phrases__container--item-special {
            @include special--container();
            > p {
              color: $color--white;
            }

            &:hover {
                background-color: light(#591ab7);
            }
        }
    }
}
