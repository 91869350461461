/*****************************
*     Wins ratio box skin    *
*****************************/


.wins-ratio-box__wrapper {
    @include default--container();
}

.wins-ratio-box__container--tournament-wins {
    @include default--container();  
}