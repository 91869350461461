.gf__container--input {
  background-color: #ede6e2;
  box-shadow: 0 -4px 13px #7c5c41;
}

.gf__input--message {
  background-color: #f9f9f9;
  border: none;
}

.gf__button--bclass {
  color: $color--black;
}

.gf__input--drawer {
  background-color: #ede6e2;
  height: calc(100% - 6rem) !important;
  @media screen and (max-width: $break-point--768) {
  }
}
