$gray: #a6c5ca;
$dark-gray: #9fa3a5;
$light-gray: #ebeeef;
$bg-gray: #edf0f1;

.custom-select__wrapper {
    position: unset !important;
}

.custom-select__container {
    position: unset !important;
    &.custom--select__options--visible {
        .custom-select__container--options {
            background-color: $color__white;
            border: none;
            box-shadow: 0 0 3rem .5rem $black__transparent--07;
            border-radius: .8rem .8rem .8rem .8rem;
            left: 0;
            top: 6rem;
            &.button {
                border: .1rem solid $accent--1;
                border-radius: .8rem;
            }
        }
        .custom-select__input {
            @include input--container();
            padding: 0 1rem 0 2rem !important;
            .custom-select__dropdown-arrow {
                // color: $popup-font--color;
            }
        }
    }

    .custom-select__input {
        position: unset !important;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        line-height: inherit;
        padding: 0 1rem 0 2rem !important;
        @include input--container();
        > p {
            font-size: $text__size--normal;
            // color: $popup-font--color;
        }
        .custom-select__dropdown-arrow {
            // color: $popup-font--color;
        }
        // color: $popup-font--color;
    }
}

.custom-select__container--options {
    @include default--container();
    @include scrollbar(0.4rem, 0.2rem, $dark-gray, transparent, 0, 10%, true, $light-gray);
    background-color: transparent;
    border: none;
    width: 100% !important;
    top: 6rem;
    @media only screen and (max-width: $break-point--768) {
        top: 6rem !important;
    }
}

.custom-select__icon-selected {
    // color: $popup-accent-1;
}