$tier0-5-color: #2c6875;
$tier1-color: #ffc8a6;
$tier2-color: #232323;
$tier3-color: #432a07;
$tier4-color: $color--white;
$tier5-color: #ffbc00;
$tier6-color: #ffbc00;

.mr--scroll__restriction--cover {
    .restriction-level--host {
        position: absolute;
        top: 42% !important;
        left: 2rem !important;
        margin: 0 !important;
    }
}
//     font-size: $text__size--normal;

//     &.tier0-5 {
//         color: $tier0-5-color;
//     }

//     &.tier1 {
//         color: $tier1-color;
//     }

//     &.tier2 {
//         color: $tier2-color;
//     }

//     &.tier3 {
//         color: $tier3-color;
//     }

//     &.tier4 {
//         color: $tier4-color;
//     }

//     &.tier5 {
//         color: $tier5-color;
//     }

//     &.tier6 {
//         color: $tier6-color;
//     }
// }

.mr--scroll__wrapper {
    background-repeat: no-repeat;
    background-size: cover;

    &.tier0 {
        background-image: url(imageUrl("lobby/amateurs.png"));
    }

    &.tier1 {
        background-image: url(imageUrl("lobby/beginners.png"));
    }

    &.tier2 {
        background-image: url(imageUrl("lobby/advanced.png"));
    }

    &.tier3 {
        background-image: url(imageUrl("lobby/professionals.png"));
    }

    &.tier4 {
        background-image: url(imageUrl("lobby/masters.png"));
    }

    &.tier5 {
        background-image: url(imageUrl("lobby/legends.png"));
    }

    &.tier6 {
        background-image: url(imageUrl("lobby/supreme.png"));
    }

    &.tier7 {
        background-image: url(imageUrl("lobby/geniuses.png"));
    }
    
    &.tier8 {
        background-image: url(imageUrl("lobby/elite.png"));
    }

    &.tier9 {
        background-image: url(imageUrl("lobby/sultans.png"));
    }

    &.quickRound {
        background-image: url(imageUrl("lobby/quick_round.png"));
    }
}

.mr--scroll__button-bclass {
    font-size: $text__size--large;
    height: 4.5rem;
}

.mr--scroll__button {
    width: 80% !important;
    margin-left: 10% !important;
}

.mr--scroll__room-name {
    font-size: 1.6rem !important;
    color: $color__white;

    @media screen and (max-width: $break-point--768) {
        font-size: 1.5rem !important;
    }
}

.mr--scroll__room-name-p {
    text-shadow: 0 6px 12px rgb(0 0 0 / 70%);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.mr--scroll__room--info {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;

    &.non-gambling-style-enabled {
        display: none !important;
    }
}

.mr--scroll__room--info--p {
    display: flex;
    align-items: center;
    margin: 0 .5rem;
    word-break: break-word;
}

.mr--scroll__room--info--icon {
    width: 2rem !important;
}

.mr--scroll__room--info--span {
    margin: 0 0.5rem;
    font-weight: $font-weight-light;
    vertical-align: middle;
}

.mr--scroll-reward__label {
    font-weight: $font-weight-black !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mr--scroll-reward__value-label {
    font-weight: $font-weight-black !important;
}

.mr--scroll-bet__value-label {
    font-weight: $font-weight-black !important;
    font-size: $text__size--normal;
    margin-right: .5rem;
}

.mr--scroll-reward__value, .mr--scroll-bet__value {
    font-weight: $font-weight-black !important;
}

// .mr--scroll__restriction--cover__container {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: $black__transparent--05;
//     display: none;
//     border-radius: 1.2rem;
//     &.mr--scroll__restriction--cover__container__not-permitted {
//         display: block;
//         background-color: $black__transparent--05;
//     }
// }

// .mr--scroll__restriction--cover {
//     visibility: hidden !important;
// }

.mr--scroll__transactions {
    height: 25%;
    width: 90%;
}

.mr--scroll__room-name {
    top: 50% !important;
    height: 25% !important;
    width: 55% !important;
    > p {
        padding: 1rem;
        border-radius: .8rem;
        background-color: $black__transparent--05;
    }
}

.mr--scroll__container--wrapper-play {
    margin-top: 10rem !important;
}

.mr--scroll__players-counter {
    background-color: $black__transparent--05 !important;
    color: $color--white;
    padding: 0.5rem !important;
    border-radius: 10rem;
    top: 42%;
    min-width: 6rem;
    right: 2rem;
}