.ranked-tile-component {
    border-radius: $container-border--radius;
    background: none;
    @include container--tile(#102c76, #4969b6, #0f3a9f, #04174b);
}

.ranked-room-tile__entry--currency-class {
    text-shadow: 0 1px 0 #2b0071, 0 3px 0 #3e49b3, 0 5px 5px #000;
}

.ranked-room-tile__play-btn--wrapper {
    border-radius: 10rem;
}

.ranked-room-tile__inner-glow {
    display: none !important;
}