.cashback--tile__content--container {
    @include container--tile(transparent,#c959db, #9a01ab, #670363);
}

.cashback--tile__text--container {
    background-color: transparent !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.cashback--tile__text {
    justify-content: right !important;
}
