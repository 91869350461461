.grid__host {
    min-height: 97rem !important;
    padding-top: 1rem;
}

.grid-column {
    width: 100% !important;
    height: 48% !important;
    float: left !important;

    &.cover {
        height: 50% !important;
    }

    &.keep-columns {
        height: 60% !important;   
    }

    &:not(.keep-columns) {
        &:nth-of-type(n + 3) {
            .large-tile,
            .medium-tile {
                width: 100% !important;
                height: 50% !important;
            }

            .small-tile {
                width: 50% !important;
                height: 50% !important;
            }

            .extra-small-tile {
                width: 50% !important;
                height: 25% !important;
            }
        }
    }
}

.grid-tile {
    padding: 0.5rem !important;
}