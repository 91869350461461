.club-page__top-tabs__bclass {
    width: 6rem;
    @include top--tab();
    &.top-tabs__selected {
        @include top--tab--selected();
    }
}


.club-page__list-item--navigation {
    @media screen and (max-width: $break-point--768) {
        height: 4rem !important;
    }
}

.club__top-tabs__bclass__notification__button {
    height: initial !important;
}