.gifts-shop-drawer__wrapper {
    background-color: #031955 !important;
}


// .gift-shop-drawer-button-icon-tab__selected {
//     color: $color-tab-selected;
//     > p {
//         color: $color-tab-selected;
//     }
// }

.gift-shop-drawer-button-icon-tab {
    @media only screen and (max-width: $break-point--768) {
        height: 4rem !important;
    }
}

.gift-shop-drawer-button-icon-tab__bclass {
    transition: none !important;
    @include top--tab();
    &.gift-shop-drawer-button-icon-tab__selected {
        @include top--tab--selected();
    }
}
.gifts-shop-drawer__container--content {
    height: calc(100% - 3rem) !important;
    &.one-tab {
        height: calc(100% - 2rem) !important;
    }
    .content-gifts {
        margin-top: .5rem !important;
        height: calc(100% - 2.5rem) !important;
        margin-top: 1rem;
        @media only screen and (max-width: $break-point--768) {
          height: calc(100% - 1.5rem) !important;
        }
        .content-wrapper {
            padding: 0 2rem !important;
            background-color: transparent !important;
            @media only screen and (max-width: $break-point--768) {
                padding: 0 !important;
                margin: 0 !important;
                width: 100% !important;
            }
        }
        &.one-tab {
            height: 100% !important;
        }
    }
}