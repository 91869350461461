

.ranked-room-tile-top-bar {
    background: transparent !important;
    box-shadow: none !important;
}

.ranked-tile-top-bar-curved-path-border__class {
    display: none !important;
}

.ranked-tile-top-bar__element {
    font-size: $text__size--large;
    color: $color--white;

    background-color: #04113f;
    box-shadow: none !important;
}
