@mixin scrollbar(
    $width: 0.2rem,
    $height: 0.2rem,
    $color: #236dbc,
    $background: rgba(255, 255, 255, 0.2),
    $thumb-border-radius: 0,
    $darken: 10%,
    $ie: true,
    $background-color: transparent
) {
    @at-root .prime:not(.no-touch) & {
        /* WebKit */
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 0;
            height: 0;
            display: none;
        }
        /* Firefox */
        scrollbar-width: none;
    }
    @at-root .no-touch & {
            &::-webkit-scrollbar {
                width: $width !important;
                height: $height !important;
                background: $background-color;
                -webkit-appearance: none !important;
            }

            &::-webkit-scrollbar-button {
                width: 0 !important;
                height: 0 !important;
            }

            &::-webkit-scrollbar-thumb {
                background: $color !important;
                border: none !important;
                border-radius: $thumb-border-radius !important;
                width: $width !important;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $color !important;
            }

            &::-webkit-scrollbar-thumb:active {
                background: $color !important;
            }

            &::-webkit-scrollbar-track {
                background: $background !important;
                border: none !important;
                border-radius: 0 !important;
            }

            &::-webkit-scrollbar-track:hover {
                background: $background !important;
            }
            &::-webkit-scrollbar-track:active {
                background: $background !important;
            }
            &::-webkit-scrollbar-corner {
                background: transparent !important;
            }
    }
}
