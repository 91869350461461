.game-history-box__text--text-game-status {
  .game-history-box__text--sign {
    margin-bottom: .3rem;
  }
}

.game-history-box__container--game-played {
  @include default--container();
  border-radius: 2rem !important;
}

.game-history-box__container--room-type {
  > p {
    font-weight: $font-weight-bold;
    font-size: $text__size--large;
  }
}

.game-history-box__container--upper-info {
  background-color: transparent !important;
}