/**********************************
*      Global feed                *
**********************************/
.gf__wrapper--convs {
	background-size: 30rem;
}

.gf__container--conversations {
	background-color: #ede6e2;
}

.gf__container--messages {
	background-color: #ede6e2;
	@include scrollbar(0.4rem, 0.2rem, #906d51);
}
