// Variables
$container__cover: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAxCAYAAAAbb8MkAAABwHpUWHRSYXcgcHJvZmlsZSB0eXBlIGV4aWYAAHjanVRrbsMgDP7PKXYE/IiB49AUpN1gx99nAlvSVtU0W8GOjd9OQvv67OHDwcSCbilbMYsALVq4gsnxAOdveBjP/RBliwsolnEOCIsBvShkUuKrfFGqD450RqfbVbHknB8c8QzkAcCTTkdlMiQQv4hsJaeov+91BuizREubWk5bsLsxRxFuwnjSEU1QmmSp4ryfqBASAr9JGqc7Hs4QPXmPZkbDhKGmQq5Y8lGAzM6f5COUK9As9xUeS4l8KJ7kcpWvYSwIIz2BWk5G9noy7wYT3k1mlHVqtOrk+Cr3CYZzer3fc+9t3NCqhgW1uWGrSJr3sKbqNWyoRB0DmO1gQR0NLd2x22lix27fkO9OFDsx0KmDkNFOCVSpBtqGMEOfqdId2AcKJWZWZmqsMGiQtZ9caIZNI3CLOSB2wyWFM6X2fwx/vdj77i0i/6Dl6BbyYh+LF4tmixNcwxQoz3ZKfNiSC9BocF42NGzCeyO0VgxVTyP9meAThPPwj8tEb/NZi+L/qn2mgeKO0qL0GJvkuuNjx78MLKcauZS846eXY0WDUod5Cd/QjhUQ/gxjyAAAAARzQklUCAgICHwIZIgAAACISURBVAiZZcxLisQwEATRyvDn/pfzJbxrW7SkUtk1CxsGZjbJg4SwzBRmZpiZ/s62bWJdV7Esi5imSez7LuZ5FqUUHp3nKXrv8P1+3zcioLUmjuMAdxeZCWMMUWuFiBClFOHucF3Xq1qrGGPAfd96KrVWyEzRexettd+onqi7/5OZvYoIIenzA6LXaps4JYpaAAAAAElFTkSuQmCC');
$input__color--icons: $secondary;

$container__shadow--inset: inset 0 0.1rem 0.5rem 0.2rem $black__transparent--05;
$container__shadow: 0 .4rem .7rem $black__transparent--05;

$container--normal: 'normal';
$container--dark: 'dark';
$container--info: 'info';
$container--content: 'content';
$container--striped: 'striped';
$container--default: 'default';

// Container colors
$container-dark: #000000;
$container--1: #00fff2;
$container--2: #ff00ea;
$container--3: #363795;
$container--4: #041a8a;
$container--5: #048a2c;
$container--6: #7a0350;
$container--7: #ff0303;
$container--8: #003f82;
$container--9: #111111;
