.ranked-games-leaderboard-list__container {
    border-top: .8rem;
}

.ranked-games-leaderboard-list__title-text {
    text-shadow: 0 4px 5px #000;
}

.ranked-games-leaderboard-list {
    padding: 0 !important;
}

.ranked-games-leaderboard-list__container {
    @include ranked-tiles-colors((border-color: #843ca4,
    border-top-height: 4px, 
    bg-color: #641b77,
    inner-padding: 0,
    height: 100%,
    box-shadow-bottom: #400f51,
    box-shadow-side: #7a1e98));
}

.ranked-games-leaderboard-list__title-text {
    display: none;
    &.in-leaderboard {
        padding: 2rem;
        display: block !important;
    }
}