.promo-code__container--main {
  padding: 0 2rem !important;
}

.promo-code__container--coins {
  display: none !important;
  &:before {
    display: none !important;
  }
  &:after {
    display: none !important;
  }
}


$promo-code-background: imageUrl('shop/promo_codes/promo-card.png');

// Styles
.promo-code__wrapper {
  background: url($promo-code-background) no-repeat;
  background-size: cover !important;
}

.promo-code__container--input {
  .promo-code__input {
    background-color: #02124359;
    box-shadow: inset 0 0 15px #25011e;
    border: none !important;
  }
}