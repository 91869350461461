.casino--room__wrapper {
    border-radius: $border--radius;
    background-image: url(imageUrl("lobby/BettingWheel.png"));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include container--tile(transparent, #ac5fe9, #6500af, #4a2261);
}

.casino--room__text--container {
    background-color: transparent !important;
    width: 50%;
    justify-content: right !important;
    height: initial !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.casino--room__text {
    max-width: 100% !important;
}

.casino--room__text--name {
    text-align: right;
}