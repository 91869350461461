.ranked-games-leaderboard-stats {
    background: #0c44cd;
}

.ranked-games-leaderboard__title {
    color: #d6daff;
    text-shadow: 0 -1px 0 #fafeff, 0 1px 0px #986d8c, 0 2px 0px #000;
}

.ranked-games-leaderboard-stats {
    border-radius: $container-border--radius;
    background: none;
    @include container--tile(#102c76, #4969b6, #0f3a9f, #04174b);
}

.ranked-games-leaderboard-stats__info-wrapper {
    background: transparent;
}