.club--jr--status__container {
    background-color: transparent !important;
    border: none !important;
}

.club--jr--status__label {
    text-shadow: none !important;
}

.club--jr--status__icon--status {
    margin: auto 0 !important;
    font-size: 3.5rem !important;
}