.restriction-level--host {
    font-size: 1.4rem;
    padding: .5rem 1rem;
    @media only screen and (max-width: $break-point--768) {
        font-size: 1.4rem;
    }
}

.restriction__level {
    line-height: initial;
    fill: inherit;
    color: inherit;
    text-transform: none !important;
    margin: 0 !important;
    word-break: break-word !important;
    text-align: center;

    .restriction__icon {
        margin: 0 0.4em 0 0;
        font-size: 1.4rem;
    }
}
