.rankings-scroll-tile__wrapper {
    // @include container--tile(transparent, #93315f, #a11b34, #630611);
    //ramadan
    @include container--tile(transparent,#c959db, #9a01ab, #670363);
}

.rankings-scroll-tile__wrapper {
    background-image: url(imageUrl("lobby/leaderboardBackground.png"));
}

.rankings-scroll-tile__wrapper {
    &.rankings-scroll-tile__extra-medium-tile {
        background-image: none !important;
        background-color: #092a7b !important;
        border-top: 2px solid #3b65bc !important;
        box-shadow: inset 0 -2px 0 #031a58, inset 2px 0 0 #003093,inset -2px 0 0 #003093 !important;
        .rankings-scroll-tile__leaders--top {
            display: none;
        }

        .rankings-scroll-tile__leaders--images {
            background-image: url(imageUrl("lobby/Podium.png"));
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 3rem 0 1rem;
        }
        .rankings-scroll-tile__leaders {
            height: 100%;
            display: flex;
            flex-direction: column;
            margin: 0 !important;
        }

        .rankings-scroll-tile__title-container {
            position: absolute;
            background-color: $black__transparent--06;
            border-radius: .8rem;
            padding: 1rem;
            top: 56%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: initial !important;
            z-index: 1;

            display: none !important;
        }

        .rankings-scroll-tile__leaders__rankings-icons {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 2rem;
        }

        .rankings-scroll-tile__leaders__rankings-icons__icon {
            width: 3rem;
            height: 3rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.first {
                background-image: url(imageUrl("lobby/Ranks-1.png"));
                order: 2;
            }
            &.second {
                background-image: url(imageUrl("lobby/Ranks-2.png"));
                margin-top: .5rem;
                order: 1;
            }
            &.third {
                background-image: url(imageUrl("lobby/Ranks-3.png"));
                margin-top: .5rem;
                order: 3;
            }
        }
        .rankings-scroll-tile__professionals__wrapper {
            width: fit-content;
            margin: 0 auto;
        }
        .rankings-scroll-tile__professionals {
            width: fit-content;
            display: block;
        }
    }
}
.rankings-scroll-tile__wrapper {
    &.rankings-scroll-tile__extra-small-tile {
        .rankings-scroll-tile__title-container {
            display: none !important;
        }
        .rankings-scroll-tile-bclass {
            height: 6.5rem !important;
            width: 5.5rem !important;
            @media screen and (max-width: $break-point--1024) {
                height: 6.5rem !important;
                width: 5.5rem !important;
            }
            @media screen and (max-width: $break-point--480) {
                height: 6.5rem !important;
                width: 5.5rem !important;
            }
        }
    }

    &.rankings-scroll-tile__extra-medium-tile {
        .rankings-scroll-tile-bclass {
            height: 9rem !important;
            width: 8rem !important;
            @media screen and (max-width: $break-point--1024) {
                height: 9rem !important;
                width: 8rem !important;
            }
            @media screen and (max-width: $break-point--480) {
                height: 9rem !important;
                width: 8rem !important;
            }
        }
    }
}
