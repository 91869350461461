.challenges-dropdown__top-tabs__bclass {
    @include top--tab();

    &.daily-challenges-dropdown__top-tabs__bclass__selected {
        @include top--tab--selected();
    }

    &.weekly-challenges-dropdown__top-tabs__bclass__selected {
        @include top--tab--selected();
    }
}

.challenges-dropdown__weekly-challenges {
    background-image: url(imageUrl("backgrounds/bg.svg")), linear-gradient(to bottom, #840069, #7d0071, #730079, #670082, #56008b);
    background-repeat: repeat;
    box-shadow: inset 0px -8rem 8rem 0px #b7009e !important;
    border-top: 1px solid #ff00ff;
    background-size: 27%;
}

.challenges-dropdown__container--weekly-challenge {
    border: none;
}

.challenges-dropdown__container--weekly-challenge .pib__wrapper {
    background-image: linear-gradient(to bottom, #7d00ff, #5d0edd, #3f11bc, #220f9a, #000b79);
    border-top: 2px solid #f600ff;
    box-shadow: 0 2px 5px 0px #011034, inset 0px 0px 9px 2px #8f00f7;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container {
    box-shadow: 0px 1px 2px 0px #bd00ff !important;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container.progress-classic :not(.overrided):last-child {
    box-shadow: inset 0px 1px 2px 0px #ffffb4 !important;
    background-color: #ffc800 !important;
}

.challenge-box--progress-info-box--skippies-button {
    border-radius: 1.2rem;
    background-image: linear-gradient(to bottom, #6b00da, #5d01ca, #5001b9, #4301a9, #360099);
    box-shadow: inset 0px -2px 2px 0px #00002a, inset 0px 2px 1px 0px #d100fd;
}