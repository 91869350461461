body {
  font-family: $skin-font-family;
  background: url(imageUrl( 'backgrounds/bg.png' )), #031955 repeat center;
  background-size: contain;

  background: url(imageUrl( 'backgrounds/royal-palace.png' )), #031955 repeat center; // post ramadan season
  background-size: 25%; // post ramadan season

  @media screen and (max-width: $break-point--850) {
    background-size: 90%; // post ramadan season
  }
}

button {
  >p {
    font-family: $skin-font-family;
  }
}

input {
  font-family: $skin-font-family;
}

textarea {
  font-family: $skin-font-family;
}

::-webkit-input-placeholder {
  color: #999;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
}

.element-disabled {
  opacity: .5;
}

.element-disabled:hover {
  cursor: pointer !important;
}