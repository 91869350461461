.bdropdown--header {
    border: none;
    background: none;
    box-shadow: none;
}

.bdropdown--body {
    background-color: $color__white;
    border: none !important;
    transform: translateX(-50%) translateY(.2rem) !important;
    p {
        color: #001d5e !important;
        text-decoration: none !important;
    }
    > *:hover {
        background-color: lighten(#001d5e, 65%);
        border-radius: $radius--default;
    }
    &.downward {
        border-radius: $radius--default !important;
    }
}
