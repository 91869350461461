.item-box__image-wrapper {
    min-height: 7.5rem;
}

@media only screen and (max-width:$break-point--480) {
    .item-box__image-wrapper {
        min-height: 6rem;
    }
}

@media only screen and (max-width: $break-point--380) {
    .item-box__value {
        &.title {
            font-size: 1.4rem!important;
        }
    }
}