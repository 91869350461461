.happy-hour-container {
    border-radius: 2rem;
    box-shadow: 0 2px 0 #b07a23, 2px 0 0 #e6d27e, -2px 0 0 #e6d27e, 0 9px 6px #011034;
    border-top: .2rem solid #fff6a4;
    border-bottom: none;
    background: url(imageUrl('shop/happy-hour-banner-royal-palace.png')), linear-gradient(to bottom, #4c3793, #422c82, #392271, #2f1761, #250d51);; // post ramadan theme
    background-size: contain; /// ramadan theme
    background-position: bottom; // ramadan theme
    position: relative; // ramadan theme

    @media screen and (max-width: $break-point--380) {
        background-size: cover; // ramadan theme
    }
}

.happy-hour__title {
    color: #f6e6d6; // autumn theme
    text-shadow: 0 1px 0 #3F1204, 0 2px 1px #00000047, 0 3px 0 #3F1204; // autumn theme

    @media screen and (max-width: $break-point--440) {
        font-size: 2.5rem;
    }
}

.happy-hour__description {
    font-size: $text__size--large;
    margin-top: .5rem;
    color: #f6e6d6; // autumn theme
    text-shadow: 0 1px 0 #3F1204, 0 2px 1px #00000047, 0 3px 0 #3F1204; // autumn theme

    >span {
        color: #f6e6d6; // autumn theme
        text-shadow: 0 1px 0 #3F1204, 0 2px 1px #00000047, 0 3px 0 #3F1204; // autumn theme
    }

    @media screen and (max-width: $break-point--568) {
        font-size: 1.6rem;
    }

    @media screen and (max-width: $break-point--440) {
        font-size: $text__size--normal;
    }
}

.happy-hour-flag__container {
    background: #ca4a00 !important;
}