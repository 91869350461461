.sic__container {
  @include default--container();
}

.sic-host-class {
  border: none;
}

.sic__spinner--cmp {
  border-radius: $container-border--radius;
}