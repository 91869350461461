%platform-skinned-popup-title-bar {
  background: linear-gradient(90deg,#010b1d,#0044da 4%,#092a7b 13%,#031955 50%,#092a7b 87%,#0044da 96%,#010b1d);
}

.popup-title-bar--platform-skinned-popup-title-bar {
    @extend %platform-skinned-popup-title-bar;
}

.popup-title-bar__container--close,
.popup-title-bar__container--back-button {
  top: 50% !important;
  height: 4rem !important;
  transform: translateY(-50%);
}

.popup-title-bar__container--close-redesign {
    display: none;
}

.popup-title-bar__container--back-button {
  right: auto;
  left: 1rem;
}

.popup-title-bar__container--game-title {
  box-shadow: inset 0 -5px 0 #02093d;
  font-weight: $font-weight-bold;
  @extend %platform-skinned-popup-title-bar;
  color: #f4e4d4;
    &:before {
      content: none !important;
  }
  .popup-title-bar__text--static-title {
    text-shadow: 1px -1px 0 $color--black;
  }
  .popup-title-bar__image--game-title {
    @include background-image($game__logo, auto, 100%);
  }
}

.popup-title-bar__text--static-title {
    font-size: 1.5em;
    text-transform: uppercase;
    @media only screen and (max-width: $break-point--480),
        screen and (max-width: $break-point--768) and ((orientation: landscape)) {
        font-size: 1.2em;
    }
}

.title-bar-close-button__bclass {
  background-color: #bd2119;
  border-radius: 10rem;
  min-width: 4rem !important;
  height: 4rem !important;
  min-height: 4rem !important;
  font-size: 2rem !important;
  box-shadow: inset 0 3px 0 #d1251c, 0 3px 2px #410b09;
}