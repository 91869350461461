.challenge-progress-info-box--wrapper-weekly {
    background-image: linear-gradient(to bottom, #7d00ff, #5d0edd, #3f11bc, #220f9a, #000b79);
    border-top: 2px solid #f600ff;
    box-shadow: 0 2px 5px 0px #011034, inset 0px 0px 9px 2px #8f00f7;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .challenge-progress-info-box__progress-bar .progress-bar__progress--container.progress-classic :not(.overrided):last-child {
    box-shadow: inset 0px 1px 2px 0px #ffffb4 !important;
    background-color: #ffc800 !important;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .progress-bar__wrapper .progress-bar__progress--container {
    box-shadow: 0px 1px 2px 0px #bd00ff !important;
}

.challenge-progress-info-box--wrapper-daily {
    @include default--container();
}